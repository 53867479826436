import { Collapse } from 'antd';
import React from 'react';

const { Panel } = Collapse;

const ProfileCardToggle = () => (
  <>
    <Collapse
      className="profile-card-toggle-accordion"
      bordered={false} // Remove the border for a custom look
    >
      <Panel header="Profile" key="1" showArrow={false} className="custom-panel">
        <div className="profile-details">
          <li><strong>Patient’s full name: </strong> Sulaiman Something</li>
          <li><strong>Gender: </strong> Male</li>
          <li><strong>Date of Birth: </strong> 15/2/1965</li>
          <li><strong>IC number (Malaysian): </strong> 651213-03-5671</li>
          <li><strong>Contact Number: </strong> 011-11234517</li>
          <li><strong>Email address: </strong> sulaiman101@gmail.com</li>
        </div>
      </Panel>
    </Collapse>
  </>
);

export default ProfileCardToggle;
