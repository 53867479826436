import { Button, DatePicker, Select, message } from 'antd';
import React, { useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import NoteEditor from './NoteEditor';
import './TicketDrawer.css';
import { Link } from 'react-router-dom';
const { Option } = Select;

const TicketTabs = ({ticket, onClose}) => {

  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [isReschedule, setIsReschedule] = useState(false);
  const [firstChoiceDate, setFirstChoiceDate] = useState(null);
  const [secondChoiceDate, setSecondChoiceDate] = useState(null);

  const handleDoctorChange = (value) => {
    setSelectedDoctor(value);
  };

  const handleFirstChoiceDateChange = (value, dateString) => {
    setFirstChoiceDate(dateString);
  };

  const handleSecondChoiceDateChange = (value, dateString) => {
    setSecondChoiceDate(dateString);
  };

  const handleConfirm = () => {
    if (selectedDoctor && firstChoiceDate && secondChoiceDate) {
      console.log('Doctor:', selectedDoctor);
      console.log('1st Choice Date:', firstChoiceDate);
      console.log('2nd Choice Date:', secondChoiceDate);
      onClose();
    } else {
      message.error('Please select a doctor and both appointment dates.');
    }
  };

  if (!ticket) return null;


  return (
    <>
    <div className="ticket-details">
      <div className="ticket-details-group">
        <h2>Ticket Information</h2>
        <p><strong>Ticket Details:</strong> Complaint</p>
        <p><strong>Department:</strong> Human Resource (HR) Department</p>
        <p><strong>Complaint:</strong> The nurse are not very friendly and the food taste bland. When i ask for the doctor, the nurse didn’t entertain my request. </p>

      </div>
    </div>

      <NoteEditor title="Ticket Notes" />
      <div className="ticket-details">
        
        <div className="drawer-buttons" style={{ marginTop: '20px' }}>
          <Button type="primary" style={{ marginRight: 10 }} onClick={handleConfirm}>Forward</Button>
          <Button type="primary" danger onClick={onClose}>Cancel</Button>
        </div>
      </div>
    </>
  );
};

export default TicketTabs;
