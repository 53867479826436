import { Button, Form, Input } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';

function CreateCategoryScreen() {
  const history = useNavigate();

  const onFinish = (values) => {
    console.log('Success:', values);
    // Here you can handle the form submission, such as sending data to your server

    // Navigate back to the category list after submission
    // history.push('/category-list');
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
    <Header />
      <div className="list-of-blog-wrapper">
      <div className="container">
        <div className="table-header">
            <div className="table-title">
              <h2>Create New Category</h2>
            </div>
            <div className="table-right">
              <button className='default-btn'>
                <Link to="/create-blog">
                  Save
                </Link>
              </button>
            </div>
          </div>
      <Form
        name="create-category"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Category Name"
          name="name"
          rules={[{ required: true, message: 'Please input the category name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Slug"
          name="slug"
          rules={[{ required: true, message: 'Please input the slug!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      </div>
      </div>
    </>
  );
}

export default CreateCategoryScreen;
