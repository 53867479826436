import { CloseOutlined } from '@ant-design/icons';
import { Drawer, Tabs } from 'antd';
import React, { useState } from 'react';
import AllTabs from './AllTabs';
import AppointmentTabs from './ApponitmentTabs';
import './TicketDrawer.css';
import TicketTabs from './TicketTabs';

const TicketDrawer = ({ visible, onClose, ticket, toggleRefresh, setSelectedTicket }) => {
  const user = JSON.parse(localStorage.getItem('userInfo'))

  const items = ticket?.ticketStatus === 'pending' ? [
    {
      key: '1',
      label: 'Info',
      children: <AllTabs ticket={ticket} onClose={onClose} toggleRefresh={toggleRefresh} setSelectedTicket={setSelectedTicket}/>,
    }
  ] : ((ticket?.ticketStatus === 'open' || ticket?.ticketStatus === 'closed') && (ticket?.type === 'new_appointment' || ticket?.type === 'reschedule_appointment')) ? [
    {
      key: '1',
      label: 'Info',
      children: <AllTabs ticket={ticket} onClose={onClose} toggleRefresh={toggleRefresh} setSelectedTicket={setSelectedTicket}/>,
    },
    {
      key: '3',
      label: 'Appointment',
      children: <AppointmentTabs ticket={ticket} onClose={onClose} toggleRefresh={toggleRefresh}/>,
    }
  ] : ((ticket?.ticketStatus === 'open' || ticket?.ticketStatus === 'closed') && (ticket?.type === 'cancel_appointment')) ? [
    {
      key: '1',
      label: 'Info',
      children: <AllTabs ticket={ticket} onClose={onClose} toggleRefresh={toggleRefresh} setSelectedTicket={setSelectedTicket}/>,
    }
  ] : [
    {
      key: '1',
      label: 'Info',
      children: <AllTabs ticket={ticket} onClose={onClose} toggleRefresh={toggleRefresh} setSelectedTicket={setSelectedTicket}/>,
    },
    {
      key: '4',
      label: 'General',
      children: <TicketTabs ticket={ticket} onClose={onClose} />,
    }
  ];


  if (!ticket) return null;

  return (
    <Drawer
      title={`Hi ${user?.first_name} ${user.last_name}`}
      className='right-sidebar-drawar'
      placement="right"
      onClose={onClose}
      visible={visible}
      width="90%"
      closeIcon={<CloseOutlined />}
    >
      
      {/* <div className="ticket-drawar-title">
            <h2></h2>
          </div> */}
      <div className="ticket-sidebar-body-wrap">
        <div className="ticket-sidebar-box">
          <h3>New Ticket</h3>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
      </div>
    </Drawer>
  );
};

export default TicketDrawer;
