import { Button, Checkbox, Form, Input, Modal } from 'antd';
import axios from 'axios';
import { apiUrl } from '../../Variables';
import React, { useState } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import toast from 'react-hot-toast';

const CreateGroupModal = ({toggleReset}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [userInput, setUserInput] = useState({
    name: ""
  })

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    await axios.post(`${apiUrl}/groups/createGroup`, {...userInput, users: [userInfo._id]}).then(res => {
      console.log(res)
      setUserInput({})
      toggleReset()
      toast.success("Group created successfully!")
      setIsModalOpen(false);
    }).catch(err => {
      toast.error("Group creation failed!")
      console.log(err)
    })
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <div className="add-new-group" onClick={showModal}>
        <FiPlusCircle />
        <span>Create Group</span>
      </div>
      <Modal title="Create Group" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form
          name="basic"
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Group Name"
            name="name"
            value={userInput.name}
            onChange= {e => setUserInput({...userInput, name: e.target.value})}
            rules={[
              {
                required: true,
                message: 'Please input your group name!',
              },
            ]}
          >
            <Input />
          </Form.Item>

        </Form>
      </Modal>
    </>
  );
};

export default CreateGroupModal;
