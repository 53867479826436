import React, { useState } from 'react';
import { DatePicker, Input, Select } from 'antd';
import { Search, Calendar, ChevronDown, RotateCcw } from 'lucide-react';
import './FilterBar.css';

const { RangePicker } = DatePicker;
const { Option } = Select;

const FilterBar = ({ onSearch, onDateChange, onStatusChange, onReset }) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedDates, setSelectedDates] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    onSearch(value);
  };

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
    onDateChange(dates);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
    onStatusChange(value);
  };

  const handleReset = () => {
    setSearchValue('');
    setSelectedDates(null);
    setSelectedStatus(null);
    onReset();
  };

  const commonStyles = {
    marginRight: '10px',
    marginBottom: '10px',
    '&:hover, &:focus': {
      borderColor: 'var(--primary-color)',
      boxShadow: '0 0 0 2px rgba(150, 127, 101, 0.2)',
    },
    '&:focus': {
      borderColor: 'var(--primary-bold-color)',
    },
  };

  return (
    <div className="flex justify-start items-center gap-0 mb-3">
      <Input
        placeholder="Search by name or phone"
        value={searchValue}
        onChange={handleSearch}
        prefix={<Search size={16} />}
        style={{ ...commonStyles, width: 300 }}
      />
      <RangePicker
        value={selectedDates}
        onChange={handleDateChange}
        style={commonStyles}
        suffixIcon={<Calendar size={16} />}
      />
      <Select
        placeholder="Select status"
        value={selectedStatus}
        onChange={handleStatusChange}
        style={{ ...commonStyles, width: 150 }}
        suffixIcon={<ChevronDown size={16} />}
      >
        <Option value="">All</Option>
        <Option value="open">Open</Option>
        <Option value="pending">Pending</Option>
        <Option value="closed">Close</Option>
      </Select>
      <button
        onClick={handleReset}
        className="reset-button flex items-center gap-1 border border-primary-color rounded-md px-2 py-1"
        style={commonStyles}
      >
        <RotateCcw size={16} />
        Reset
      </button>
    </div>
  );
};

export default FilterBar;
