import { Space, Table } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';

const columns = [
  {
    title: 'Category Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Slug',
    dataIndex: 'slug',
    key: 'slug',
  },
  {
    title: 'Total Posts Count',
    dataIndex: 'totalPosts',
    key: 'totalPosts',
  },
  {
    title: 'Created Date',
    dataIndex: 'createdDate',
    key: 'createdDate',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a>Edit</a>
        <a>Delete</a>
      </Space>
    ),
  },
];

const data = [
  {
    key: '1',
    name: 'Web Development',
    slug: 'web-development',
    totalPosts: 10,
    createdDate: '2023-05-01',
  },
  {
    key: '2',
    name: 'Programming',
    slug: 'programming',
    totalPosts: 8,
    createdDate: '2023-06-15',
  },
  {
    key: '3',
    name: 'Web Design',
    slug: 'web-design',
    totalPosts: 5,
    createdDate: '2023-07-10',
  },
  {
    key: '4',
    name: 'Digital Marketing',
    slug: 'digital-marketing',
    totalPosts: 12,
    createdDate: '2023-04-20',
  },
  {
    key: '5',
    name: 'Database',
    slug: 'database',
    totalPosts: 6,
    createdDate: '2023-03-30',
  },
  {
    key: '6',
    name: 'Artificial Intelligence',
    slug: 'artificial-intelligence',
    totalPosts: 9,
    createdDate: '2023-02-25',
  },
  {
    key: '7',
    name: 'Personal Development',
    slug: 'personal-development',
    totalPosts: 7,
    createdDate: '2023-01-05',
  },
  {
    key: '8',
    name: 'DevOps',
    slug: 'devops',
    totalPosts: 11,
    createdDate: '2023-08-19',
  },
];

function ListOfCategoryScreen() {
  return (
    <>
      <Header />
      <div className="container">
        <div className="list-of-blog-wrapper">
          <div className="table-header">
            <div className="table-title">
              <h2>Category List</h2>
            </div>
            <div className="table-right">
              <button className="default-btn">
                <Link to="/create-category">
                  Add New Category
                </Link>
              </button>
            </div>
          </div>
          <Table columns={columns} dataSource={data} pagination={{ pageSize: 10 }} />
        </div>
      </div>
    </>
  );
}

export default ListOfCategoryScreen;
