import { Tag } from "antd";

const columns = [
  {
    title: 'Ticket Type',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: 'Patient Name',
    dataIndex: 'fullName',
    key: 'fullName',
    render: (text) => <b>{text}</b>,
  },
  {
    title: 'Phone',
    dataIndex: 'contact',
    key: 'contact',
  },
  {
    title: 'IC/Passport',
    dataIndex: 'idPassport',
    key: 'idPassport',
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Status',
    dataIndex: 'ticketStatus',
    key: 'ticketStatus',
    render: (status) => {
      let color;
      if (status === 'open') color = 'green';
      else if (status === 'pending') color = 'gold';
      else color = 'red';
      return <Tag color={color}>{status}</Tag>;
    },
  },
];

export default columns;
