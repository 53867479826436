import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BsTrash, BsX } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import GroupChatlog from '../../components/omnichannel/GroupChatlogs';
import { apiUrl } from '../../Variables';

import Skeleton from 'react-loading-skeleton'; // Import the Skeleton component
import 'react-loading-skeleton/dist/skeleton.css';

import NewLayout from '../../components/NewLayout';

import { Users } from 'lucide-react';
import toast from 'react-hot-toast';
import ProfileCardToggle from '../../components/omnichannel/ProfileCardToggle';
import CreateGroupModal from './CreateGroupModal';
import GroupMemberLists from './GroupMemberLists';


const imageNotFound =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtWkiWDS6J0sln0jthemckUqQxaA6dmbP9WFM0EtS7iOHmoDy1MsVonWIPyPOPYfZb3kc&usqp=CAU';

function GroupScreen({ toggleLoggedInState }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [createGroupToggle, setCreateGroupToggle] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [users, setUsers] = useState()
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterDate, setFilterDate] = useState({
    startDate: '',
    endDate: '',
    // startDate: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
    // endDate: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]
  });
  console.log(filterDate);
  const [searchInput, setSearchInput] = useState('');
  const [filteredChats, setFilteredChats] = useState([]);

  const location = useLocation();

  const [resetData, setResetData] = useState(false);
  const toggleReset = () => {
    setResetData(!resetData);
  };
  const [chats, setChats] = useState(null);
  const [groupList, setGroupList] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  // create group model
  const handleCreateGroup = () => {
    setCreateGroupToggle(!createGroupToggle);
  };

  // fetch group data []
  useEffect(() => {
    const fetchGroup = async () => {
      try {
        setLoading(true);
        const res = await axios.get(`${apiUrl}/groups`);
        setGroupList(res.data);
        console.log(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Group fetch error:', error);
      }
    };
    fetchGroup();
  }, [resetData]);

  const handleDelete = async (id) => {
    const confirm = window.confirm("Are you sure?")
    if (confirm) {
      try {
        setLoading(true);
        const res = await axios.delete(`${apiUrl}/groups/${id}`);
        setSelectedGroup(null)
        toggleReset()
        toast.success('deleted successfully!')
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error('deleted failed!')
        console.log('Group fetch error:', error);
      }
    }
  }

  // Function to handle search input changes
  const handleSearchInputChange = (e) => {
    e.preventDefault();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    // Filter chats based on the search term
    setLoading(true);
    axios
      .post(
        `${apiUrl}/chats/search`,
        { keyword: searchInput.toLowerCase() },
        { headers }
      )
      .then((res) => {
        console.log(res);
        setFilteredChats(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    // const filtered = chats.filter((item) => item.senderId.toLowerCase().includes(searchInput.toLowerCase()));
  };

  return (
    <>
      <Helmet>
        <title>Omnichannel | AECUS Dashboard</title>
      </Helmet>
      <NewLayout>
        <div className="chat-body-wrapper">
            {/* omni sidebar and chatlogs */}
            <div className="omni-sidebar-and-chatlogs">
              {/* sidebar */}
              <div className="chat-body-sidebar">
                <div className="tab-content-wrapper">
                  <div className="chatbox-sidebar-wrap">
                    {/* <div className="chat-title chat-title-big title-and-filter-result-wrap">
                      <h2>Chatlogs</h2>
                      
                      <MdImportExport title='export' onClick={handleExport} className="add-new-group-icon" />
                    </div> */}
                    {/* search */}
                    <div className="search-box-wrap">
                      <div
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                          if (
                            selectedItems.length ===
                            (searchInput ||
                            (filterDate.startDate && filterDate.endDate)
                              ? filteredChats
                              : chats
                            ).length
                          ) {
                            setSelectedItems([]);
                          } else {
                            setSelectedItems(
                              (searchInput ||
                              (filterDate.startDate && filterDate.endDate)
                                ? filteredChats
                                : chats
                              ).map((item) => item._id)
                            );
                          }
                        }}
                      >
                        {/* select all option */}
                        {/* if search result is not found the hide select all box */}
                      </div>

                      {/* search input */}
                      <div className="chat-body-search">
                        <form onSubmit={handleSearchInputChange}>
                          <input
                            placeholder="Search..."
                            className="chat-search-input"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                          {/* <FiSearch className="selected-icon chatlog-search-icon" /> */}

                          {/* Conditionally render the cancel icon */}
                          {searchInput && (
                            <>
                              {!loading && (
                                <BsX
                                  className="cancel-icon"
                                  onClick={() => {
                                    // Clear the search input and reset filtered chats when cancel is clicked
                                    setSearchInput('');
                                    setFilteredChats([]);
                                  }}
                                />
                              )}

                              {/* <p className='search-btn' onClick={() => handleSearchInputChange()}>{loading ? 'Searching...' : 'Search'}</p> */}
                              <button className="search-btn" type="submit">
                                {loading ? 'Searching...' : 'Search'}
                              </button>
                            </>
                          )}
                        </form>
                      </div>
                    </div>

                    <div className="recent-chat group-member-wrapper groups">
                      {
                        !loading ? (
                          groupList?.filter(item => item.users?.includes(userInfo._id))?.map((item, index) => (
                            <li
                              key={index}
                              className='recent-chat-item pending group-member-item'
                              onClick={() => setSelectedGroup(item)}
                            >
                              <div className="recent-chat-item-inner">
                                <div className="recent-chat-item-content">
                                  <div className="recent-chat-avatar flex items-center justify-center border border-gray-50 rounded-full">
                                    {/* <img src={groupIcon} alt="people" /> */}
                                    <Users />
                                  </div>

                                  {/* content */}
                                  <div className="recent-chatbox-content">
                                    <h2>{item.name}</h2>
                                    <div className="recent-chatbox-user-chat">
                                      <div className="chat-list-sub-content">
                                        <span>{moment(item?.updatedAt).subtract(1, 'minute').fromNow()}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="channel-right">
                                  <BsTrash onClick={() => handleDelete(item._id)}/>
                                </div>
                              </div>
                            </li>
                          ))
                        ) : (
                          // Show skeletons while chats are loading
                          <ul className='recent-chat'>
                            {[...Array(5)].map((_, index) => (
                              <li key={index} className='recent-chat-item pending group-member-item'>
                                <div className="recent-chat-item-inner">
                                  <div className="recent-chat-item-content">
                                    <div className="recent-chat-avatar">
                                      <Skeleton circle height={40} width={40} />
                                    </div>

                                    {/* content */}
                                    <div className="recent-chatbox-content">
                                      <h2><Skeleton width={100} /></h2>
                                      <div className="recent-chatbox-user-chat">
                                        <Skeleton width={150} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )
                      }
                    </div>

                        {/* create group */}
                        <CreateGroupModal toggleReset={toggleReset} />
                  </div>
                </div>
              </div>

              {/* message body */}
              <div className="chat-message-body chatbox-message-body active">
                <GroupChatlog
                  loading={loading}
                  setLoading={setLoading}
                  selectedGroup={selectedGroup}
                  users={users}
                />
              </div>
            </div>

            {/* profile overview */}
            <div className="omni-profile-overview">
              {/* profile card */}
              <div className="omni-profile-card">
                {selectedGroup ? (
                  <>
                    <div className="omni-profile-card-static">
                      {/* <img src={groupIcon} alt="profile overview" /> */}
                      <Users className='w-8 h-8 mt-3' />
                      <h2>{selectedGroup?.name}</h2>
                    </div>
                    <div className="profile-card-toggle-content">
                      <ProfileCardToggle />
                    </div>
                  </>
                ) : (
                  <>
                    <Skeleton circle height={60} width={60} />
                    <h2><Skeleton width={120} /></h2>
                    <p><Skeleton width={80} /></p>
                  </>
                )}
              </div>

              {/* ticket history */}
              <GroupMemberLists users={users} setUsers={setUsers} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} toggleReset={toggleReset} />
            </div>
        </div>
      </NewLayout>
    </>
  );
}

export default GroupScreen;
