import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarDetailSidebar from './CalendarDetailSidebar';
import NewLayout from '../../components/NewLayout';
import axios from 'axios';
import { apiUrl } from '../../Variables';
import { useSearchParams } from 'react-router-dom';

const localizer = momentLocalizer(moment);


const CalendarScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const apptId = searchParams?.get("apptId")
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [apptDate, setApptDate] = useState(null);

  useEffect(() => {
    const fetchGroup = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${apiUrl}/appointments`);
        const apptList = res.data.filter(ite => ite.appointmentStatus !== 'cancelled')?.map(event => {
          return {
            id: event._id,
            title: event.fullName,
            start: new Date(event.appointmentDate),
            end: new Date(event.appointmentDate),
            allDay: false,
            description: event.note,
            doctor: event.doctor,
            idPassport: event.idPassport
          }
        })
        updateSelectedAppt(apptList)
        setEvents(apptList);
        // console.log(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Chats fetch error:', error);
      }
    };
    fetchGroup();
  }, [apptId])

  function updateSelectedAppt (eventList) {
    console.log(eventList, apptId)
    if (apptId) {
      const appt = eventList.find(item => item.id === apptId)
      setSelectedEvent(appt)
      setApptDate(appt?.start)
    }
  }
  useEffect(() => {
    updateSelectedAppt(events)
  }, [apptId])

  const CustomEvent = ({ event }) => (
    <div className="meeting-active-block">
      <p className="meeting-active-title">{event.title}</p>
      <div className="meeting-time-wrap">
        <p>Start: {moment(event.start).format('h:mm a')}</p>
      </div>
    </div>
  );

  const handleSelectEvent = (event) => {
    console.log('Selected event:', event);
    setSelectedEvent(event);
  };

  const handleDrawerClose = () => {
    setSelectedEvent(null);
  };

  return (
    <NewLayout>
      <div className='calendar-container'>
        <div className="calendar-right-body">
              <CalendarDetailSidebar
                event={selectedEvent}
                onClose={handleDrawerClose}
              />
              {/* <CalendarEvents /> */}
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                components={{ event: CustomEvent }}
                onSelectEvent={handleSelectEvent} // Handle event click
                className="react-body-big-calendar"
                showAllEvents="true"
                date={apptDate}
                onNavigate={date => {
                  setApptDate(date);
                  setSearchParams({})
                }}
              />
        </div>
      </div>
    </NewLayout>
  );
};

export default CalendarScreen;
