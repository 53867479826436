import { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import BotScreen from './screens/BotScreen';
import LoginScreen from './screens/LoginScreen';
import PageNotFoundScreen from './screens/PageNotFoundScreen';
import UserDetailScreen from './screens/UserDetailScreen.js';
import CreateAuthorScreen from './screens/author/CreateAuthorScreen.js';
import ListOfAuthorScreen from './screens/author/ListOfAuthorScreen.js';
import CreateBlogScreen from './screens/blog/CreateBlogScreen.js';
import ListOfBlogScreen from './screens/blog/ListOfBlogScreen.js';
import CalenderScreen from './screens/calendar/CalendarScreen.js';
import MLDAScreen from './screens/mlDA/mlDAScreen.js';
import CreateCategoryScreen from './screens/category/CreateCategoryScreen.js';
import ListOfCategoryScreen from './screens/category/ListOfCategoryScreen.js';
import GroupScreen from './screens/group/GroupScreen.js';
import OmniChannelScreen from './screens/omnichannel/OmniChannelScreen.js';
import AllAppointmentLogScreen from './screens/ticket/AllAppointmentLogScreen.js';
import AllTicketListScreen from './screens/ticket/AllTicketListScreen.js';
import ListOfUsers from './screens/users/listOfUsers.js';
import {
  ProtectedRoute,
  PublicRoute,
  checkIsAuthenticate,
} from './utils/routeAuthenticate.js';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    JSON.parse(localStorage.getItem('isAuthenticated'))
  );
  const [loggedInState, setLoggedInState] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const toggleLoggedInState = () => {
    setLoggedInState(!loggedInState);
  };

  useEffect(() => {
    setIsAuthenticated(
      checkIsAuthenticate(JSON.parse(localStorage.getItem('userInfo')))
    );
  }, [loggedInState]);

  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem('userInfo')));
  }, [loggedInState]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <AllTicketListScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <LoginScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/bot"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <BotScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <ListOfUsers toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/tickets"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <AllTicketListScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/omnichannels"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <OmniChannelScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/appointments"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <AllAppointmentLogScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/groups"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <GroupScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/calendar"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <CalenderScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/mlDA"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <MLDAScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/blogs"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <ListOfBlogScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/create-blog"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <CreateBlogScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/categories"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <ListOfCategoryScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/create-category"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <CreateCategoryScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/authors"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <ListOfAuthorScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/create-author"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <CreateAuthorScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/user-details/:userId"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <UserDetailScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="*"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <PageNotFoundScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
