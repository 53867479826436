import EmojiPicker from 'emoji-picker-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { BsEmojiSmile, BsThreeDotsVertical } from 'react-icons/bs';
import { FaTelegramPlane } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './omnichannel.css';
import io from 'socket.io-client';


// import groupIcon from '../../images/icons/group.png';
import groupIcon from '../../images/icons/group-icon.png';
import peopleTwo from '../../images/peoples/Avatar02.png';
import { Users } from 'lucide-react';


function GroupChatlogs({ selectedGroup, users  }) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [socket, setSocket] = useState(null);
  const [refresh, setRefresh] = useState(false);
  function toggleRefresh () {
    setRefresh(!refresh)
  }
  const [chat, setChat] = useState({
    groupId: '',
    sessionStartTime: '',
    utilityData: {},
    channel: '',
    chats: []
  });
  const [message, setMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const botRef = useRef(null);

  const handleEmojiClick = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const onEmojiClick = (emojiObject) => {
    setMessage(message + emojiObject.emoji); // Append selected emoji to the message
  };
  const handleDocumentClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('File selected:', file);
      // Handle the file, e.g., upload it or show a preview
    }
  };
  useEffect(()=> {
    botRef.current?.scrollIntoView({ behavior: "smooth" })
  },[chat.chats])
  
  //socket io functionality start
  useEffect(() => {
    // Connect to the server
    const newSocket = io('wss://apiaecus.aceva.com.my', {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000
    }); // Replace with node js server URL
    // const newSocket = io('ws://35.247.176.128:5000'); // Replace with node js server URL
    // const newSocket = io('ws://localhost:5000'); // Replace with node js server URL
    setSocket(newSocket);

    // Join the group chat room
    newSocket.emit('join group', { groupId: selectedGroup?._id });

    // Listen for chat history
    newSocket.on('load history', (chatObj) => {
      console.log('chatObj', chatObj)
      setChat({...chatObj})
    });
    // Listen for incoming messages
    newSocket.on('chat message', (data) => {
      setChat(prevChat => ({
        ...prevChat, // spread the previous state
        chats: [...(prevChat.chats || []), data] // spread the existing chats and add the new one
      }));
    });
    // Clean up on component unmount
    return () => {
        newSocket.disconnect();
    };
}, [selectedGroup?._id, refresh]);

useEffect(() => {
    setChat({
      groupId: '',
      sessionStartTime: '',
      utilityData: {},
      channel: '',
      chats: []
    })
}, [selectedGroup?._id]);

const handleSendMessage = async (e) => {
  e.preventDefault();
  if (message.trim() && socket) {
    // Send the message to the server
    socket.emit('chat message', {
        groupId: selectedGroup?._id,
        senderId: userInfo._id,
        message
    });
    toggleRefresh()
    setMessage(''); // Clear the input field
}
};

  return (
    <>
      <ToastContainer />
      <div className="chatbox-wrapper">
        {/* chatbot widget wrapper */}
        <div className="chatbox">
          {/* new header */}
          <li className="recent-chat-item omni-chat-header">
            <div className="recent-chat-item-inner">
              <div className="recent-chat-item-content">
                <div className="recent-chat-avatar flex items-center justify-center border border-gray-50 rounded-full">
                  {selectedGroup ? (
                    // <img src={groupIcon} alt="people" />
                    <Users />
                  ) : (
                    <Skeleton circle height={40} width={40} />
                  )}
                </div>
                <div className="recent-chatbox-content">
                  <h2>{selectedGroup ? selectedGroup?.name : <Skeleton width={100} />}</h2>
                  <div className="recent-chatbox-user-chat">
                    <div className="chat-list-sub-content">
                      <span>{chat ? moment(chat.updatedAt).subtract(1, 'minute').fromNow() : <Skeleton width={150} />}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="channel-right">
                {chat ? <BsThreeDotsVertical /> : <Skeleton width={20} />}
              </div>
            </div>
          </li>
          {/* chat header */}

          {/* chat conversation group body */}
          <div className="chat-conversation-wrapper chatbox-conversation">
            <ul className="message-body">
              {/* Static messages */}
              {
                chat?.chats?.map(item => <div className="chat-message-wrapper">
                    <div className="text-and-date-wrap">
                      <div className="message-bubble-agent user"
                        dangerouslySetInnerHTML={{
                          __html: `<p class="name-title">${item.senderId === userInfo._id ? "Me" : users.find(i => i._id === item.senderId)?.first_name}</p>
                          <p class="chat-text">${item.message}</p>`,
                        }}></div>
                      <p className="date-badge">{moment(item.time).subtract(1, 'minute').fromNow()}</p>
                    </div>
                  </div>)
              }


              <li ref={botRef}></li>
            </ul>
          </div>

          {/* chat footer */}
          <form className="chatbox-conversation-form" onSubmit={handleSendMessage}>
            <div className="chatbot-footer chatbox-footer">
              <div className="chatbot-footer-input-box">
                <input value={message} onChange={(e) => setMessage(e.target.value)} type="text" placeholder="Message..." />
                <div className="omni-footer-button-wrap">
                <BsEmojiSmile onClick={handleEmojiClick} />
                {showEmojiPicker && <EmojiPicker className='chat-emoji-box' onEmojiClick={onEmojiClick} />}
                <IoDocumentTextOutline onClick={handleDocumentClick} />

                  <FaTelegramPlane className="chatbot-send-message-button chatlogs-send-btn" />
                </div>
              </div>
            </div>
            <input
              id="fileInput"
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileInputChange}
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default GroupChatlogs;

