import axios from 'axios';
import { RefreshCw, Send, X } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { BsChatRightText } from 'react-icons/bs';
import { apiUrl } from '../Variables';
import { default as logo, default as textLogo } from '../images/bot-agent.webp';

// swiper slider
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Add custom styles for Swiper navigation arrows
const swiperStyles = `
  .swiper-button-next,
  .swiper-button-prev {
    color: #ffffff;
    background-color: rgb(150 127 101);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 14px;
  }

  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .swiper-button-prev, .swiper-button-next {
    top: var(--swiper-navigation-top-offset, 43%) !important;
  }
`;

const menuItems = [
  { title: "Treatment", payload: "Treatments" },
  { title: "Medical Diagnostic", payload: "Medical Diagnostic" },
  { title: "Appointment", payload: "/appointment" },
  { title: "Other Enquiries", payload: "/other_enquiries" }
];

const detailedMenu = [
  {
    mainMenu: "Treatments",
    submenus: [
      {
        name: "EECP",
        carouselData: [
          {
            name: "Grow New Arteries",
            img: "https://vitamedicalcare.my/wp-content/uploads/2023/07/Vita-EECP-Benefits-Image1-1024x683.jpg"
          },
          {
            name: "Improve Artery Function",
            img: "https://vitamedicalcare.my/wp-content/uploads/2023/07/Vita-EECP-Benefits-Image3-1024x683.jpg"
          },
          {
            name: "Improve Heart Function",
            img: "https://vitamedicalcare.my/wp-content/uploads/2023/07/Vita-EECP-Benefits-Image2-1024x683.jpg"
          }
        ]
      },
      {
        name: "IV THERAPY",
        carouselData: [
          {
            name: "MESENCHYMAL STEM CELLS",
            img: "https://vitamedicalcare.my/wp-content/uploads/2023/07/Vita-IV-Therapy-Stem-Cell-1024x683.jpg",
            desc: "Stem cells hold considerable promise for the treatment of many diseases. They have several advantages over embryonic stem cells because they are found in many adult tissues and do not pose any moral dilemmas. We believe they can be used in both autologous and allogeneic transplantations due to their special qualities, such as their simplicity in isolation and culture, availability in numerous tissues, their immunomodulatory abilities, and the absence of ethical difficulties brought on by their usage.<br/>One of the most often used cell types for regenerative medicine is mesenchymal stem cells (MSCs). Numerous studies have demonstrated the effectiveness of MSC-based therapies in the treatment of a variety of pathologies, including neurological illnesses, heart ischemia, diabetes, and diseases of the bone and cartilage."
          },
          {
            name: "NAD+ THERAPY",
            img: "https://vitamedicalcare.my/wp-content/uploads/2023/07/Vita-NAD-Therapy-1024x683.jpg",
            desc: "NAD+, also known as nicotinamide adenine dinucleotide, is a naturally occurring coenzyme that supports several body functions. It controls circadian rhythms, promotes metabolism, disarms free radicals, and, most significantly, makes it possible for mitochondria to turn food into energy. Unfortunately, as we age, NAD+ production decreases, leaving us with less energy, more ageing symptoms, and an increased risk of sickness. However, keeping NAD+ levels high can aid in preventing these problems, and we are pleased to offer NAD+ IV therapy in all of our centres."
          },
          {
            name: "STEM CELL IV INFUSION",
            img: "https://vitamedicalcare.my/wp-content/uploads/2023/07/Vita-IV-Infusion-1024x683.jpg",
            desc: "Our CellEssence 100 Million Exosome Stem Cell IV Infusion is a medical procedure where a high concentration of exosomes obtained from stem cells are directly injected into a patient’s bloodstream through an intravenous (IV) infusion. These exosomes are small vesicles that play a vital role in cell communication and contain different molecules such as proteins, lipids, and nucleic acids.<br/>Stem cell-derived exosomes have therapeutic advantages such as enhancing tissue repair and regeneration, reducing inflammation, and controlling immune responses. The 100 million exosome stem cell IV infusion describes the number of exosomes given during the treatment.<br/>This medical treatment may be suggested for individuals with specific health conditions such as chronic inflammation, autoimmune disorders, and neurodegenerative diseases. Keep in mind that the suitability of this treatment depends on individual factors and must be evaluated by a qualified medical expert."
          },
          {
            name: "IV NUTRIENT THERAPY",
            img: "https://vitamedicalcare.my/wp-content/uploads/2023/06/Vita-IV-NUTRIENT-THERAPY-1024x683.jpg",
            desc: "Introducing IV Nutrient Therapy, a powerful nutritional supplement that offers a range of potential benefits for a diverse group of individuals with various health issues.<br/>For those with weakened immune systems, IV Nutrient Therapy provides immune-supportive nutrients such as vitamin C, zinc, and selenium that can help strengthen the body’s defenses against illness and disease."
          }
        ]
      },
      {
        name: "EMSELLA",
        carouselData: [
          {
            name: "PELVIC FLOOR HEALTH",
            img: "https://vitamedicalcare.my/wp-content/uploads/2023/07/Vita-Emsella-Benefits-Image1-1024x683.jpg"
          },
          {
            name: "INCONTINENCE",
            img: "https://vitamedicalcare.my/wp-content/uploads/2023/07/Vita-Emsella-Benefits-Image2-1024x683.jpg"
          },
          {
            name: "SEXUAL HEALTH",
            img: "https://vitamedicalcare.my/wp-content/uploads/2023/07/Vita-Emsella-Benefits-Image3-1024x683.jpg"
          }
        ]
      },
      {
        name: "BEMER",
        carouselData: null
      }
    ]
  },
  {
    mainMenu: "Medical Diagnostic",
    submenus: [
      { name: "BIO RESONANCE SCAN", carouselData: null },
      { name: "LIVE BLOOD CELL ANALYSIS", carouselData: null },
      { name: "MICROCIRCULATION ANALYSIS", carouselData: null },
      { name: "COMPREHENSIVE BLOOD TEST", carouselData: null }
    ]
  }
];


const ModernChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [chatLists, setChatLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ip, setIp] = useState('');

  const chatRef = useRef(null);

  useEffect(() => {
    const generateUniqueId = () => {
      return `W${new Date().getTime()}${Math.floor(Math.random() * 900) + 100}`.replace(/\./g, 'U');
    };

    if (!ip) {
      setIp(generateUniqueId());
    }
  }, [ip]);

  useEffect(() => {
    chatRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatLists, loading]);

  const toggleChat = () => setIsOpen(!isOpen);

  const resetChat = () => {
    setChatLists([]);
    handleBotMessage('/restart');
  };

  const handleBotMessage = async (msg) => {
    setLoading(true);

    // Check if it's a main menu item
    const mainMenu = detailedMenu.find(menu => menu.mainMenu.toLowerCase() === msg.toLowerCase());
    if (mainMenu) {
      const submenus = mainMenu.submenus.map(submenu => { return { title: submenu.name, payload: submenu.name } });
      setChatLists([...chatLists, {bot: {text: `Here are the options under ${mainMenu.mainMenu}:`, type:'menu', payload: submenus}, user: msg, time: "today's date'"}]);
      setMessage('')
      setLoading(false);
      return; // Early return as we've handled the menu selection
    }

    // Check if it's a submenu item
    for (let menu of detailedMenu) {
      const submenu = menu?.submenus?.find(sub => sub.name.toLowerCase() === msg.toLowerCase());
      if (submenu) {
        const carouselData = submenu.carouselData;
        const updatedChat = {
          bot: {
            text: carouselData ? `Here is more information on ${submenu.name}:` : `No data available on ${submenu.name}:`,
            type: 'carousel',
            payload: carouselData
          },
          user: msg,
          time: new Date().toLocaleString()
        };
        setChatLists([...chatLists, updatedChat]);
        setLoading(false);
        return; // Early return as we've handled the submenu selection
      }
    }

    // Check if it's a carousel item
    for (let menu of detailedMenu) {
      for (let smenu of menu.submenus) {
          const carouselData = smenu?.carouselData?.find(crl => crl.name.toLowerCase() === msg.toLowerCase());;
          if (carouselData) {
            const updatedChat = {
              bot: {
                text: carouselData?.desc,
              },
              user: msg,
              time: new Date().toLocaleString()
            };
            setChatLists([...chatLists, updatedChat]);
            setLoading(false);
            return; // Early return as we've handled the submenu selection
          }
      }
    }

    const data = { senderId: ip, message: msg, senderName: 'Admin' };
    
    try {
      const chatArr = [...chatLists, {bot: {text: '', payload: []}, user: msg, time: "today's date'"}]
      setChatLists(chatArr)
      setMessage('')

      const response = await axios.post(`${apiUrl}/chats/web_chat`, data);
      if (msg === '/restart') {
        setChatLists([...chatLists, {bot: {text: `<p class='text-base'><strong>Welcome to AECUS - your Chat Support.</strong></p>
        <p class='text-base'> Please select from below options to connect to specific chatbot.</p>`, type:'menu', payload: menuItems}, user: msg, time: "today's date'"}]);
      } else {
        setChatLists([...chatLists, response.data])
      }
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputSubmit = async (e, msg) => {
    e?.preventDefault()
    if (!message && !msg) {
      console.log('retrun from onsubmit')
      return
    }
    setLoading(true)
    const tempMsg = message
    const data = { senderId: ip, message: tempMsg || msg, senderName: 'Admin' };
    console.log(data)
    const chatArr = [...chatLists, {bot: {text: '', payload: []}, user: tempMsg || msg, time: "today's date'"}]
    setChatLists(chatArr)
    setMessage('')
    await axios.post(`${apiUrl}/chats/web_chat`, data).then( res => {
        console.log(res, chatLists?.length - 1)
        // chatLists[chatLists?.length - 1] = res.data
        setChatLists([...chatLists, res.data])
        setMessage('')
        setLoading(false)
      }).catch( error => {
        setLoading(false)
        console.log(error)
        // setError(true)
      })
  }

  const renderChatMessages = () => {
    return chatLists.map((item, index) => (
      <div key={index} className="mb-2 mt-2">
        {item.user && (
          <div className="flex justify-end mr-2">
            <div className="!bg-primary text-white rounded-lg py-1 px-2 max-w-xs shadow-md">
              <p className='text-sm !font-normal text-white'>{item.user}</p>
            </div>
          </div>
        )}
        {item.bot && (
          <>
            <div className="flex items-start mt-3">
              <img src={logo} alt="bot" className="w-8 h-8 rounded-full mr-2 shadow-sm object-contain p-[2px]" />
              <div className="bg-gray-100 rounded-lg py-2 px-3 max-w-xs pb-3">
                <p className='text-base' dangerouslySetInnerHTML={{__html: item.bot.text}}></p>
                <div className="w-full bg-white rounded-lg overflow-hidden border border-gray-100">
              {
                item.bot.type === 'menu' && item.bot.payload?.map((mnu, index) => (
                  <div 
                    key={index}
                    onClick={() => handleBotMessage(mnu.payload)}
                    className={`
                      px-4 py-2 text-center text-base !text-primary font-medium hover:!text-primary-bold
                      border-b border-gray-200 cursor-pointer
                      hover:bg-gray-50 transition-colors duration-150
                      ${index ===  item.bot?.payload?.length - 1 ? 'border-b-0' : ''}
                    `}
                  >
                    {mnu.title}
                  </div>
                ))
              }
              </div>
              {(item.bot.payload && !item.bot.type) && (
                <div className="mt-2 flex flex-wrap gap-2">
                  {item.bot.payload.map((btn, btnIndex) => (
                    <button 
                      key={btnIndex}
                      onClick={() => handleBotMessage(btn.payload)}
                      className="bg-white !text-primary border !border-primary rounded-full px-3 py-1 text-sm hover:bg-blue-50 transition-colors shadow-sm !hover:text-primary-bold !font-medium"
                    >
                      {btn.title}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
            {
              (item?.bot?.type === 'carousel' && item.bot?.payload) && <div className='w-full mt-2'>
                <style>{swiperStyles}</style>
                <div className="flex items-start mt-3">
                  <Swiper
                  slidesPerView={3}
                  spaceBetween={0}
                  slidesPerGroup={1}
                  centeredSlides={true}
                  loop={true}
                  loopFillGroupWithBlank={false}
                  navigation={true}
                  modules={[Navigation]}
                  className="chatbot-swiper-container"
                >
                  {
                    item.bot?.payload?.map((carsl, carslIndex) => (
                        <SwiperSlide onClick={() => handleBotMessage(carsl.name)} key={carslIndex}>
                          <div className="" key={carslIndex}>
                            <img className='w-24 h-24 rounded object-contain mb-2' src={carsl.img} alt={carsl.name} />
                            <p className='text-base'>{carsl.name}</p>
                          </div>
                        </SwiperSlide>
                      ))
                  }
                  </Swiper>
              </div>
            </div>
            }
          </>
        )}
      </div>
    ));
  };

  return (
    <div className="fixed bottom-8 right-8 z-50">
      {isOpen && (
        <div className="bg-white rounded-2xl shadow-2xl !w-[27rem] sm:w-96 flex flex-col h-[40rem] overflow-hidden">
          <div className="!bg-primary text-white py-2 px-4 rounded-t-2xl flex justify-between items-center">
            <div className="flex items-center">
              <img src={textLogo} alt="Bot Logo" className="w-12 h-12 mr-2 rounded-full shadow-md bg-white object-contain p-[2px]" />
              <div>
                <h2 className="text-lg font-medium text-white">Chat with us</h2>
              </div>
            </div>
            
            <div className="flex items-center gap-3">
              <RefreshCw onClick={resetChat} className="cursor-pointer text-white hover:text-blue-200 transition-colors" size={20} />
              <X onClick={toggleChat} className="text-white cursor-pointer hover:text-blue-200 transition-colors" size={20} />
            </div>
          </div>
          <div className="flex-grow overflow-y-auto p-4 px-3 bg-gray-50">

            {/* menu items */}
            <div className="flex items-start mt-3">
              <img src={logo} alt="bot" className="w-8 h-8 rounded-full mr-2 shadow object-contain p-[2px]" />
              <div className="bg-gray-100 rounded-lg py-2 px-3 max-w-xs pb-3">
                <p className='text-base'><strong>Welcome to AECUS - your Chat Support.</strong></p>
                <p className='text-base'> Please select from below options to connect to specific chatbot.</p>
                <div className="w-full bg-white rounded-lg overflow-hidden border border-gray-100">
                {menuItems.map((item, index) => (
                  <div 
                    key={index}
                    onClick={() => handleBotMessage(item.payload)}
                    className={`
                      px-4 py-2 text-center text-base !text-primary font-medium hover:!text-primary-bold
                      border-b border-gray-200 cursor-pointer
                      hover:bg-gray-50 transition-colors duration-150
                      ${index === menuItems.length - 1 ? 'border-b-0' : ''}
                    `}
                  >
                    {item.title}
                  </div>
                ))}
                </div>
              </div>
            </div>
            {/* end menu items */}
            {renderChatMessages()}
            {loading && (
            <div className="flex items-center">
              <img src={logo} alt="bot" className="w-8 h-8 rounded-full mr-2 shadow-sm object-contain p-[2px]" />
              <div className="bg-gray-100 rounded-lg py-3 px-4 shadow-xs">
                <div className="flex items-center justify-center gap-1">
                  <div className="w-2 h-2 !bg-primary rounded-full animate-bounce"></div>
                  <div className="w-2 h-2 !bg-primary rounded-full animate-bounce" style={{animationDelay: '0.2s'}}></div>
                  <div className="w-2 h-2 !bg-primary rounded-full animate-bounce" style={{animationDelay: '0.4s'}}></div>
                </div>
                </div>
              </div>
            )}
            <div ref={chatRef}></div>
          </div>
          <form onSubmit={handleInputSubmit} className="border-t p-3 bg-white">
            <div className="flex rounded-full bg-gray-100 shadow-inner">
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type a message..."
                className="flex-grow px-4 py-2 bg-white !border !border-border-primary rounded-full rounded-br-none rounded-tr-none !focus:outline-0"
              />
              <button type="submit" className="!bg-primary text-white !rounded-full !rounded-tl-none !rounded-bl-none py-2 px-2 flex items-center justify-center w-14 h-12 hover:bg-primary-bold transition-colors">
                <Send className='text-white mr-2' size={22} />
              </button>
            </div>
          </form>
        </div>
      )}
      {!isOpen && (
        <button onClick={toggleChat} className="!bg-primary text-white rounded-full p-4 py-3 flex items-center shadow-lg !hover:bg-primary-bold transition-colors text-base">
          <BsChatRightText size={24} className="mr-2 text-white" />
          <span className="hidden sm:inline">Chat With Us</span>
        </button>
      )}
    </div>
  );
}

export default ModernChatBot;