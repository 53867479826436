import React, { useState, useEffect, Fragment } from 'react';
import NewLayout from '../../components/NewLayout';


const mlDAScreen = () => {

  return (
    <NewLayout>
      <div style={{ height: '100vh' }}>
        <iframe src="https://analytics.wipdata.com/" style={{height:'100%', width:"100%"}}/>
      </div>
    </NewLayout>
  );
};

export default mlDAScreen;
