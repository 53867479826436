import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import './AllTicketListScreen.css';
import columns from './columns';
import dataSource from './dataSource';
import FilterBar from './FilterBar';
import Kpi from './Kpi';
import TicketDrawer from './TicketDrawer';
import { apiUrl } from '../../Variables';
import axios from 'axios';
import {formatDateToHumanReadable} from '../../utils/helper'
import NewLayout from '../../components/NewLayout';
import { useSearchParams } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const AllTicketListScreen = ({ toggleLoggedInState }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchTicketId = searchParams?.get('ticketId')
  const [filteredData, setFilteredData] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const toggleRefresh = () => {
    setRefresh(!refresh)
  }

  useEffect(() => {
    axios.get(`${apiUrl}/tickets`).then(res => {
      console.log(res)
      const tickets = res.data.map(item => { return {...item, createdAt: formatDateToHumanReadable(item.createdAt)} })
      setTicketList(tickets)
      setFilteredData(tickets)

    }).catch(err => {
      console.log(err)
    })
  }, [refresh])

  useEffect(() => {
    console.log('searchTicketId', searchTicketId)
    if (searchTicketId) {
      handleSearch(searchTicketId)
    }
  }, [searchTicketId, ticketList])

  const handleSearch = (value) => {
    const filtered = ticketList.filter(
      item =>
        item.ticketId.includes(value) ||
        item.appointmentIdRef?.includes(value) ||
        item.fullName?.toLowerCase().includes(value.toLowerCase()) ||
        item.contact?.includes(value)
    );
    setFilteredData(filtered);
  };

  const handleDateChange = (dates) => {
    if (!dates || dates.length === 0) {
      setFilteredData(ticketList);
      return;
    }

    const [start, end] = dates;
    const filtered = ticketList.filter(item => {
      const itemDate = new Date(item.createdAt);
      return itemDate >= start && itemDate <= end;
    });

    setFilteredData(filtered);
  };

  const handleStatusChange = (value) => {
    const filtered = value ? ticketList.filter(item => item.ticketStatus === value) : ticketList;
    setFilteredData(filtered);
  };

  const handleRowClick = (record) => {
    setSelectedTicket(record);
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
    setSelectedTicket(null);
  };

  const handleReset = () => {
    setFilteredData([...ticketList]);
    setCurrentPage(1);
  };

  // Dummy data for example, replace with actual ticket data handling
  const totalTickets = filteredData?.length;
  const openTickets = filteredData.filter(i => i.ticketStatus === 'open')?.length;
  const closeTickets = filteredData.filter(i => i.ticketStatus === 'closed')?.length;;
  const pendingTickets = filteredData.filter(i => i.ticketStatus === 'pending')?.length;;
  const loading = false;

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <ChevronLeft />;
    }
    if (type === 'next') {
      return <ChevronRight />;
    }
    return originalElement;
  };

  return (
    <>
      {/* <Header toggleLoggedInState={toggleLoggedInState} /> */}
      <NewLayout>
        <div className="all-ticket-list-screen">
            <div className="ticket-title">
              <h3>Ticket List</h3>
              <Kpi
                totalTickets={totalTickets}
                openTickets={openTickets}
                closeTickets={closeTickets}
                pendingTickets={pendingTickets}
                loading={loading}
              />
            </div>
          <div className="">
            <FilterBar
              onSearch={handleSearch}
              onDateChange={handleDateChange}
              onStatusChange={handleStatusChange}
              onReset={handleReset}
            />
            <Table 
              dataSource={filteredData} 
              columns={columns} 
              pagination={{
                current: currentPage,
                pageSize: 9,
                total: filteredData.length,
                onChange: (page) => setCurrentPage(page),
                showSizeChanger: false,
                itemRender: itemRender,
              }}
              rowSelection={{ type: 'checkbox' }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
              })}
              rowClassName={(record) => record.status === 'Open' ? 'open-tickets' : ''}
            />
          </div>
        </div>
        <TicketDrawer
          visible={drawerVisible}
          onClose={handleCloseDrawer}
          ticket={selectedTicket}
          setSelectedTicket={setSelectedTicket}
          toggleRefresh={toggleRefresh}
        />
      </NewLayout>
    </>
  );
};

export default AllTicketListScreen;
