import React, { useState } from 'react';
import { Button } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const NoteEditor = ({title, note, setNote}) => {

  return (
    <div className="note-container">
      <div className="note-header">
        <span>{title} :</span>
        <div className="note-footer">
          <Button type="default" className="attach-button">
            Attach Media
          </Button>
        </div>
      </div>
      <div className="note-text-editor-wrap">
        <ReactQuill 
          value={note} 
          onChange={(e) => {
            console.log(e)
            setNote(e)
          }} 
          placeholder="Type something" 
          className="note-textarea" 
        />
        
      </div>
    </div>
  );
};

export default NoteEditor;
