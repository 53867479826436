import EmojiPicker from 'emoji-picker-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { BsEmojiSmile, BsThreeDotsVertical } from 'react-icons/bs';
import { IoDocumentTextOutline, IoSend } from "react-icons/io5";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import peopleTwo from '../../images/icons/Avatar.png';
import axios from 'axios';
import './omnichannel.css';
import { apiUrl } from '../../Variables';
import { UserRound, Users } from 'lucide-react';

// import peopleTwo from '../../images/peoples/Avatar02.png';


function OmniChannelChatlogs({ chat, users, setSelectedChat  }) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [socket, setSocket] = useState(null);
  const [refresh, setRefresh] = useState(false);
  function toggleRefresh () {
    setRefresh(!refresh)
  }
  const [message, setMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const botRef = useRef(null);

  const handleEmojiClick = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const onEmojiClick = (emojiObject) => {
    setMessage(message + emojiObject.emoji); // Append selected emoji to the message
  };
  const handleDocumentClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('File selected:', file);
      // Handle the file, e.g., upload it or show a preview
    }
  };
  useEffect(()=> {
    botRef.current?.scrollIntoView({ behavior: "smooth" })
  },[chat?.chats])

  const sendMessageHandler = async (e) => {
      e.preventDefault();
      try {
        const res = await axios.post(`https://api.telegram.org/bot7199035413:AAE0LBMsvWsO8MiRmaJBSJpm11MbpSfLlCM/sendMessage`, {
          chat_id: chat.tChatId,
          text: message
        });
        setSelectedChat({...chat, liveTime: new Date().toISOString(), chats: [...chat?.chats, {
            bot: {
                text: message
            },
            user: "",
            time: new Date().toISOString()
        }]})
        await axios.put(`${apiUrl}/chats/updateForLiveChat`, { _id: chat._id, message })
        console.log(res)
        setMessage('');
      } catch (error) {
        console.log(error.message);
      }
    }


  return (
    <>
      <ToastContainer />
      <div className="chatbox-wrapper">
        {/* chatbot widget wrapper */}
        <div className="chatbox">
          {/* new header */}
          <li className="recent-chat-item omni-chat-header">
            <div className="recent-chat-item-inner">
              <div className="recent-chat-item-content">
                <div className="recent-chat-avatar flex items-center justify-center border border-gray-50 rounded-full">
                  {chat ? (
                    // <img src={peopleTwo} alt="people" />
                    <UserRound />
                  ) : (
                    <Skeleton circle height={40} width={40} />
                  )}
                </div>
                <div className="recent-chatbox-content">
                  <h2>{chat ? chat?.senderId : <Skeleton width={100} />}</h2>
                  <div className="recent-chatbox-user-chat">
                    <div className="chat-list-sub-content">
                      <span>{chat ? moment(chat.updatedAt).subtract(1, 'minute').fromNow() : <Skeleton width={150} />}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="channel-right">
                {chat ? <BsThreeDotsVertical /> : <Skeleton width={20} />}
              </div>
            </div>
          </li>
          {/* chat header */}

          {/* chat conversation group body */}
          <div className="chat-conversation-wrapper chatbox-conversation">
            <ul className="message-body">
              {/* Static messages */}
              {
                chat?.chats?.map(item => <>
               {item.user && <div className="chat-message-wrapper">
                    <div className="text-and-date-wrap user">
                      <div className="message-bubble-visitor user"
                        dangerouslySetInnerHTML={{
                          __html: `<p class="name-title">User</p>
                          <p class="chat-text">${item.user}</p>`,
                        }}></div>
                      <p className="date-badge">{moment(item.time).subtract(1, 'minute').fromNow()}</p>
                    </div>
                  </div>}
              {item?.bot?.text && <div className="chat-message-wrapper">
                    <div className="text-and-date-wrap agent">
                      <div className="message-bubble-agent agent"
                        dangerouslySetInnerHTML={{
                          __html: `<p class="name-title">Bot</p>
                          <p class="chat-text">${item.bot?.text}</p>`,
                        }}></div>
                      <p className="date-badge">{moment(item.time).subtract(1, 'minute').fromNow()}</p>
                    </div>
                  </div>}
                </>)
              }


              <li ref={botRef}></li>
            </ul>
          </div>

          {/* chat footer */}
          <form className="chatbox-conversation-form" onSubmit={sendMessageHandler}>
            <div className="chatbot-footer chatbox-footer">
              <div className="chatbot-footer-input-box">
                <input value={message} onChange={(e) => setMessage(e.target.value)} type="text" placeholder="Message..." />
                <div className="omni-footer-button-wrap">
                <BsEmojiSmile onClick={handleEmojiClick} />
                {showEmojiPicker && <EmojiPicker className='chat-emoji-box' onEmojiClick={onEmojiClick} />}
                  <IoDocumentTextOutline onClick={handleDocumentClick} />
                  <IoSend className="chatbot-send-message-button chatlogs-send-btn" onClick={sendMessageHandler}/>
                </div>
              </div>
            </div>
            <input
              id="fileInput"
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileInputChange}
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default OmniChannelChatlogs;