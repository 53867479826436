import React, { useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';

import Skeleton from 'react-loading-skeleton'; // Import the Skeleton component
import 'react-loading-skeleton/dist/skeleton.css';

import axios from 'axios';
import { apiUrl } from '../../Variables';

import CreateMemberModal from './CreateMemberModal';

function GroupMemberLists({selectedGroup, setSelectedGroup, toggleReset, users, setUsers}) {

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [loading, setLoading] = useState()
  const [filteredUser, setFilteredUser] = useState()
  const [keyword, setKeyword] = useState()
   // fetch group data []
   useEffect(() => {
    const fetchGroup = async () => {
       // Add headers to include JWT token
      const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };
      try {
        setLoading(true);
        const res = await axios.get(`${apiUrl}/user`, {headers});
        setUsers(res.data);
        console.log(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Group fetch error:', error);
      }
    };
    fetchGroup();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value
    let updatedData = []

    if (value.length) {
      updatedData = selectedGroup?.users.filter(item => {
        const usr = users.find(it => it._id === item)
        const startsWith =
          usr.first_name.toLowerCase().startsWith(value.toLowerCase()) ||
          usr.last_name.toLowerCase().startsWith(value.toLowerCase())

        const includes =
          usr.first_name.toLowerCase().includes(value.toLowerCase()) ||
          usr.last_name.toLowerCase().includes(value.toLowerCase())

        if (startsWith) {
          return startsWith
        } else if (!startsWith && includes) {
          return includes
        } else return null
      })
    }
    setFilteredUser(updatedData)
    setKeyword(value)
  }
  return (
    <>
      <div className="omni-ticket-history">
        <h2>Members</h2>
        <div className="ticket-history-filter">
          {/* <div className="form-group">
            <input type="text" placeholder='Search..' value={keyword} onChange={e => handleSearch(e)}/>
          </div> */}
          <input
            placeholder="Search..."
            className="chat-search-input"
            value={keyword}
            onChange={e => handleSearch(e)}
          />
        </div>
        {/* group member list */}
        <div className="recent-chat group-react-chat ticket-history-list group-member-wrapper">
          {/* Show loading skeletons while chats are loading */}
          {
            selectedGroup ? (
              selectedGroup?.users?.length === 0 ? <span style={{color: 'red'}}>No member found!</span> : (keyword ? filteredUser : selectedGroup?.users)?.map((item, index) => (
                <li
                  key={index}
                  className='recent-chat-item pending group-member-item'
                >
                  <div className="recent-chat-item-inner">
                    <div className="recent-chat-item-content">
                      <div className="recent-chat-avatar">
                        {item ? (
                          <img src={users.find(it => it._id === item)?.profile_image} alt="people" />
                        ) : (
                          <Skeleton circle height={40} width={40} />
                        )}
                      </div>

                      {/* content */}
                      <div className="recent-chatbox-content">
                        <h2>{item ? `${users.find(it => it._id === item)?.first_name} ${users.find(it => it._id === item)?.last_name} ${item === userInfo._id ? "(me)" : ''}` : <Skeleton width={100} />}</h2>
                        <div className="recent-chatbox-user-chat">
                          <div className="chat-list-sub-content">
                            {/* last message */}
                            <span>
                              {item ? (
                                users.find(it => it._id === item)?.email
                              ) : (
                                <Skeleton width={150} />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="channel-right">
                      {item ? <BsThreeDotsVertical /> : <Skeleton width={20} />}
                    </div>
                  </div>

                </li>
              ))
            ) : (
              // Show skeletons while chats are loading
              <ul className='recent-chat group-react-chat'>
                {[...Array(5)].map((_, index) => (
                  <li key={index} className='recent-chat-item pending group-member-item'>
                    <div className="recent-chat-item-inner">
                      <div className="recent-chat-item-content">
                        <div className="recent-chat-avatar">
                          <Skeleton circle height={40} width={40} />
                        </div>

                        {/* content */}
                        <div className="recent-chatbox-content">
                          <h2><Skeleton width={100} /></h2>
                          <div className="recent-chatbox-user-chat">
                            <div className="chat-list-sub-content">
                              <Skeleton width={150} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )
          }
        </div>

        {/* create group */}
        {selectedGroup && <CreateMemberModal selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} toggleReset={toggleReset} users={users} loading={loading}/>}
      </div>
    </>
  )
}

export default GroupMemberLists