import { Button, Checkbox, DatePicker, Select, message } from 'antd';
import React, { useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import NoteEditor from './NoteEditor';
import { apiUrl } from '../../Variables';
import axios from 'axios';
import {formatDateToHumanReadable, formatDateToHumanReadableWithTime} from '../../utils/helper'
import './TicketDrawer.css';
import toast from 'react-hot-toast';
const { Option } = Select;

const AppointmentTabs = ({ticket, onClose, toggleRefresh}) => {

  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState('');
  const [isReschedule, setIsReschedule] = useState(false);
  const [firstChoiceDate, setFirstChoiceDate] = useState(null);

  const handleDoctorChange = (value) => {
    setSelectedDoctor(value);
  };

  const handleFirstChoiceDateChange = (value, dateString) => {
    setFirstChoiceDate(dateString);
  };

  const handleSecondChoiceDateChange = (value, dateString) => {
    setFirstChoiceDate(dateString);
  };

  const handleConfirm = async () => {
    if (!firstChoiceDate) {
      toast.error('Select one date!')
      return
    }
    const userConfirmed = window.confirm('Are you sure you want to proceed?');

    if (userConfirmed) {
      setLoading(true)
      // if (selectedDoctor && firstChoiceDate && secondChoiceDate) {
        await axios.post(`${apiUrl}/appointments`, {...ticket, appointmentStatus: 'pending', note, appointmentDate: firstChoiceDate, appointmentDateTwo: ""}).then(async res => {
          console.log(res)
          toast.success('Succesfully Created!')
          await axios.put(`${apiUrl}/tickets/${ticket._id}`, {...ticket, ticketStatus: 'closed', appointmentIdRef: res.data.apptId }).then(res => {
            console.log(res)
            toggleRefresh()
            setLoading(false)
            onClose()
          }).catch(err => {
            console.log(err)
          })
        }).catch(err => {
          console.log(err)
        })
      // } else {
        // message.error('Please select a doctor and both appointment dates.');
      // }
    } else {
      // User clicked "Cancel"
      console.log('User canceled the action');
    }
  };

  const handleRescheduleConfirm = async () => {
    if (!firstChoiceDate) {
      toast.error('Select one date!')
      return
    }
    const userConfirmed = window.confirm('Are you sure you want to proceed?');

    if (userConfirmed) {
      setLoading(true)
        await axios.get(`${apiUrl}/appointments/${ticket?.appointmentIdRef}`).then(async res => {
          console.log(res)
          await axios.put(`${apiUrl}/appointments/${res.data._id}`, {...res.data, appointmentStatus: 'pending', doctor: selectedDoctor || res.data.doctor, note, appointmentDate: firstChoiceDate, appointmentDateTwo: ""}).then(async res => {
            console.log(res)
            toast.success('Succesfully Updated!')
            await axios.put(`${apiUrl}/tickets/${ticket._id}`, {...ticket, ticketStatus: 'closed', appointmentIdRef: res.data.apptId }).then(res => {
              console.log(res)
              toggleRefresh()
              setLoading(false)
              onClose()
            }).catch(err => {
              console.log(err)
            })
          }).catch(err => {
            console.log(err)
            setLoading(false)
            toast.error('Update failed!')
          })
        }).catch(err => {
          setLoading(false)
          toast.error('Invalid Appointment ID!')
          console.log(err)
        })
    } else {
      console.log('User canceled the action');
    }
  };

  return (
    <>
    <div className="ticket-details">
      <div className="ticket-details-group">
        <h2>Ticket Information</h2>
        {/* <p><strong>Ticket Details:</strong> {ticket?.note}</p> */}
        <p><strong>Ticket Type:</strong> {ticket.type}</p>
        <p><strong>Appointment date:</strong> </p>
        <p><Checkbox onChange={e => {
          if (e.target.checked) {
            setFirstChoiceDate(ticket.appointmentDate)
          }
        }} checked={firstChoiceDate === ticket.appointmentDate}/> {formatDateToHumanReadableWithTime(ticket.appointmentDate)}</p>
        <p><Checkbox onChange={e => {
          if (e.target.checked) {
            setFirstChoiceDate(ticket.appointmentDateTwo)
          }
        }} checked={firstChoiceDate === ticket.appointmentDateTwo}/> {formatDateToHumanReadableWithTime(ticket.appointmentDateTwo)}</p>
        <p><strong>Preferred Doctor (if any):</strong>{ticket.doctor}</p>
        <div className="appointment-log">
          <Link to={`/omnichannels?senderId=${ticket.senderId}`}>
            <button className="appointment-log-btn">
              Chat Logs
            </button>
          </Link>
        </div>
      </div>
    </div>
    
    <div className="select-wrap">
    <Select
        placeholder="Dr Azhari Syamsul"
        value={selectedDoctor}
        onChange={handleDoctorChange}
        style={{ width: 300, marginRight: 10 }}
        suffixIcon={<FaAngleDown />}
      >
        <Option value="Dr. Tan Kim Lee">Dr. Tan Kim Lee</Option>
        <Option value="Dr. Lim Siew Mei">Dr. Lim Siew Mei</Option>
        <Option value="Dr. Wong Yee Leng">Dr. Wong Yee Leng</Option>
        <Option value="Dr. Ahmad bin Abdullah">Dr. Ahmad bin Abdullah</Option>
        <Option value="Dr. Siti Aishah binti Hassan">Dr. Siti Aishah binti Hassan</Option>
        <Option value="Dr. Mohd Faizal bin Yusof">Dr. Mohd Faizal bin Yusof</Option>
        <Option value="Dr. Norazlina bt. Mohd Yusof">Dr. Norazlina bt. Mohd Yusof</Option>
        <Option value="Dr. Lim Wei Jie">Dr. Lim Wei Jie</Option>
        <Option value="Dr. Tan Wei Ming">Dr. Tan Wei Ming</Option>
        <Option value="Dr. Ng Mei Ling">Dr. Ng Mei Ling</Option>
      </Select>

      <DatePicker
        showTime
        placeholder="9 July 2024"
        style={{ width: 300 }}
        onChange={handleFirstChoiceDateChange}
      />
    </div>

      <NoteEditor title="Appointment Notes" note={note} setNote={setNote}/>
      <div className="ticket-details">
        
       {
         ticket?.type !== 'reschedule_appointment' ? <div className="drawer-buttons" style={{ marginTop: '20px' }}>
         <Button disabled={ticket?.ticketStatus === 'closed'} type="primary" style={{ marginRight: 10 }} onClick={handleConfirm}>{loading ? 'Scheduling...' : 'Schedule'}</Button>
         {/* <Button type="primary" danger onClick={onClose}>Cancel</Button> */}
       </div> :  <div className="drawer-buttons" style={{ marginTop: '20px' }}>
          <Button disabled={ticket?.ticketStatus === 'closed'} type="primary" style={{ marginRight: 10 }} onClick={handleRescheduleConfirm}>{loading ? 'Rescheduling...' : 'Reschedule'}</Button>
          {/* <Button type="primary" danger onClick={onClose}>Cancel</Button> */}
        </div>
       }
      </div>
    </>
  );
};

export default AppointmentTabs;
