import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BsThreeDotsVertical, BsX } from 'react-icons/bs';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import OmniChannelChatlogs from '../../components/omnichannel/OmniChannelChatlogs';
import { useAppContext } from '../../context/AppContext';
import instagram from '../../images/social-media/Ellipse159.png';
import messenger from '../../images/social-media/Ellipse160.png';
import telegram from '../../images/social-media/Ellipse161.png';
import whatsapp from '../../images/social-media/Ellipse162.png';
import line from '../../images/social-media/Ellipse163.png';
import wipdata from '../../images/social-media/Ellipse164.png';
import { apiUrl } from '../../Variables';

import Skeleton from 'react-loading-skeleton'; // Import the Skeleton component
import 'react-loading-skeleton/dist/skeleton.css';

import NewLayout from '../../components/NewLayout';
import peopleOne from '../../images/peoples/Avatar01.png';
import peopleTwo from '../../images/icons/Avatar.png';
import ProfileCardToggle from '../../components/omnichannel/ProfileCardToggle';
import { UserRound } from 'lucide-react';

const imageNotFound =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtWkiWDS6J0sln0jthemckUqQxaA6dmbP9WFM0EtS7iOHmoDy1MsVonWIPyPOPYfZb3kc&usqp=CAU';

function OmniChannelScreen({ toggleLoggedInState }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const senderId = searchParams?.get("senderId")
  const [width, setWidth] = useState(window.innerWidth);
  const [fullScreen, setFullScreen] = useState(false);
  const [createGroupToggle, setCreateGroupToggle] = useState(false);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [CSVLoading, setCSVLoading] = useState(false);
  // const [chats, setChats] = useState([]);
  const [tickets, setTickets] = useState(null);
  const [filteredTickets, setFilteredTickets] = useState(null);
  const [chatUniqueId, setChatUniqueId] = useState(null);
  const [sChatbotId, setSChatbotId] = useState('');
  const [selectToggle, setSelectToggle] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterShow, setFilterShow] = useState(false);
  const [filterDate, setFilterDate] = useState({
    startDate: '',
    endDate: '',
    // startDate: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
    // endDate: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]
  });
  console.log(filterDate);
  const [searchInput, setSearchInput] = useState('');
  const [filteredChats, setFilteredChats] = useState([]);

  const location = useLocation();
  const pathname = location.pathname;

  const { sidebarToggle } = useAppContext();

  const [resetData, setResetData] = useState(false);
  const toggleReset = () => {
    setResetData(!resetData);
  };
  const [selectedBot, setSelectedBot] = useState({ value: null, label: 'All' });
  const [chats, setChats] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);

  console.log('chats:-', chats);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  // create group model
  const handleCreateGroup = () => {
    setCreateGroupToggle(!createGroupToggle);
  };

  // fetch group data []
  useEffect(() => {
    const fetchGroup = async () => {
      try {
        // Retrieve userInfo from localStorage
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (!userInfo || !userInfo.token) {
          console.error('User token not found.');
          return;
        }

        // Add headers to include JWT token
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        };

        setLoading(true);

        await axios.get(`${apiUrl}/chats`, { headers }).then(res => {
          setChats(res.data);
          if (senderId) {
            setSelectedChat(res.data?.find(item => item.senderId === senderId))
          }
          setFilteredChats(res.data);
          console.log(res);
          setLoading(false);
        }).catch(err => {
          setLoading(false);
        })
      
      } catch (error) {
        console.log('Chats fetch error:', error);
      }
    };
    fetchGroup();
  }, [resetData]);

  useEffect(() => {
    const fetchGroup = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${apiUrl}/tickets/chatsBySenderId/${selectedChat.senderId}`);
        setTickets(res.data);
        setFilteredTickets(res.data);
        console.log(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Chats fetch error:', error);
      }
    };
    if (selectedChat?.senderId) {
      fetchGroup();
    }
  }, [selectedChat])

  useEffect(() => {
    const fetchGroup = async () => {
       // Retrieve userInfo from localStorage
       const userInfo = JSON.parse(localStorage.getItem('userInfo'));

       // Add headers to include JWT token
       const headers = {
         Authorization: `Bearer ${userInfo.token}`,
       };
      setLoading(true);
      try {
        const res1 = await axios.get(`${apiUrl}/chats/${selectedChat.senderId}`, { headers });
        setSelectedChat({...selectedChat, ...res1.data})
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Chats fetch error:', error);
      }
    };
    if (selectedChat?.senderId) {
      fetchGroup();
    }
  }, [selectedChat?.senderId])

  const handleChannelChange = channel => {
    if (channel === "all") {
      setFilteredChats(chats)
    } else {
      setFilteredChats(chats.filter(item => item.channel === channel))
    }
  }

  console.log('chats:', chats)

  // Function to handle search input changes
  const handleSearchInputChange = (e) => {
    e.preventDefault();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    // Filter chats based on the search term
    setLoading(true);
    axios
      .post(
        `${apiUrl}/chats/search`,
        { keyword: searchInput.toLowerCase() },
        { headers }
      )
      .then((res) => {
        console.log(res);
        setFilteredChats(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    // const filtered = chats.filter((item) => item.senderId.toLowerCase().includes(searchInput.toLowerCase()));
  };

  return (
    <>
      <Helmet>
        <title>Omnichannel | AECUS Dashboard</title>
      </Helmet>
      <NewLayout>
        <div className="chat-body-wrapper">
            {/* omni sidebar and chatlogs */}
            <div className="omni-sidebar-and-chatlogs">
              {/* sidebar */}
              <div className="chat-body-sidebar">
                <div className="tab-content-wrapper">
                  <div className="chatbox-sidebar-wrap">
                    {/* <div className="chat-title chat-title-big title-and-filter-result-wrap">
                      <h2>Chatlogs</h2>
                      
                      <MdImportExport title='export' onClick={handleExport} className="add-new-group-icon" />
                    </div> */}
                    {/* search */}
                    <div className="search-box-wrap">
                      <div
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                          if (
                            selectedItems.length ===
                            (searchInput ||
                            (filterDate.startDate && filterDate.endDate)
                              ? filteredChats
                              : chats
                            ).length
                          ) {
                            setSelectedItems([]);
                          } else {
                            setSelectedItems(
                              (searchInput ||
                              (filterDate.startDate && filterDate.endDate)
                                ? filteredChats
                                : chats
                              ).map((item) => item._id)
                            );
                          }
                        }}
                      >
                        {/* select all option */}
                        {/* if search result is not found the hide select all box */}
                      </div>

                      {/* search input */}
                      <div className="chat-body-search">
                        <form onSubmit={handleSearchInputChange}>
                          <input
                            placeholder="Search..."
                            className="chat-search-input"
                            value={searchInput}
                            // onChange={(e) => setSearchInput(e.target.value)}
                            onChange={(e) => {
                              setSearchInput(e.target.value)
                              setFilteredChats(chats.filter(item => item.senderId.toLowerCase().includes(e.target.value.toLowerCase())))
                            }}
                          />
                          {/* <FiSearch className="selected-icon chatlog-search-icon" /> */}

                          {/* Conditionally render the cancel icon */}
                          {/* {searchInput && (
                            <>
                              {!loading && (
                                <BsX
                                  className="cancel-icon"
                                  onClick={() => {
                                    // Clear the search input and reset filtered chats when cancel is clicked
                                    setSearchInput('');
                                    setFilteredChats(chats);
                                  }}
                                />
                              )}
                              <button className="search-btn" type="submit">
                                {loading ? 'Searching...' : 'Search'}
                              </button>
                            </>
                          )} */}
                        </form>
                      </div>
                    </div>

                    {/* omni tabs */}
                    <div className="omni-tabs">
                      <ul>
                        <li onClick={() => handleChannelChange('all')}>
                          All
                        </li>
                        <li onClick={() => handleChannelChange('web')}>
                          <img src={wipdata} alt="wipdata" />
                        </li>
                        <li onClick={() => handleChannelChange('whatsapp')}>
                          <img src={whatsapp} alt="whatsapp" />
                        </li>
                        <li onClick={() => handleChannelChange('telegram')}>
                          <img src={telegram} alt="telegram" />
                        </li>
                        {/* <li onClick={() => handleChannelChange('messenger')}>
                          <img src={messenger} alt="messenger" />
                        </li>
                        <li onClick={() => handleChannelChange('instagram')}>
                          <img src={instagram} alt="instagram" />
                        </li>
                        <li onClick={() => handleChannelChange('line')}>
                          <img src={line} alt="line" />
                        </li> */}
                      </ul>
                    </div>

                    {/* All Group list */}
                    <div className="recent-chat">
                      {/* Show loading skeletons while chats are loading */}
                      {loading ? (
                        // Skeletons displayed while loading
                        [...Array(10)].map((_, index) => (
                          <li key={index} className='recent-chat-item pending'>
                            <div className="recent-chat-item-inner">
                              <div className="recent-chat-item-content">

                                <div className="recent-chat-avatar">
                                  <Skeleton circle height={40} width={40} />
                                  <Skeleton circle height={20} width={20} className='omni-source-icon' />
                                </div>

                                {/* content */}
                                <div className="recent-chatbox-content">
                                  <h2><Skeleton width={100} /></h2>
                                  <div className="recent-chatbox-user-chat">
                                    <div className="chat-list-sub-content">
                                      {/* last message */}
                                      <Skeleton width={150} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="channel-right">
                                <Skeleton width={20} height={20} />
                              </div>
                            </div>
                          </li>
                        ))
                      ) : (
                        filteredChats?.length > 0 ? filteredChats?.map((item, index) => (
                          <li key={index} className='recent-chat-item pending' onClick={() => setSelectedChat(item)}>
                            <div className="recent-chat-item-inner">
                              <div className="recent-chat-item-content">

                                <div className="recent-chat-avatar flex items-center justify-center border border-gray-50 rounded-full">
                                  {/* <img src={peopleTwo} alt="people" /> */}
                                  <UserRound />
                                  <img
                                    className='omni-source-icon'
                                    src={
                                      item?.channel === 'web'
                                        ? wipdata
                                        : item?.channel === 'telegram'
                                        ? telegram
                                        : ''
                                    }
                                    alt="source icon"
                                  />
                                </div>

                                {/* content */}
                                <div className="recent-chatbox-content">
                                  <h2>{item.senderId}</h2>
                                  <div className="recent-chatbox-user-chat">
                                    <div className="chat-list-sub-content">
                                      {/* last message */}
                                      <span>{moment(item?.updatedAt).subtract(1, 'minute').fromNow()}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="channel-right">
                                <BsThreeDotsVertical />
                              </div>
                            </div>
                          </li>
                        )) : <span style={{color:'red'}}>No chat found!</span> 
                      )}
                    </div>

                  </div>
                </div>
              </div>

              {/* message body */}
              <div className="chat-message-body chatbox-message-body active">
                <OmniChannelChatlogs
                  loading={loading}
                  setLoading={setLoading}
                  chat={selectedChat}
                  setSelectedChat = {setSelectedChat}
                />
              </div>
            </div>

            {/* profile overview */}
            <div className="omni-profile-overview">
              {/* profile card */}
              <div className="omni-profile-card">
                {selectedChat ? (
                  <>
                    <div className="omni-profile-card-static">
                      {/* <img src={peopleTwo} alt="profile overview" /> */}
                      <UserRound className='w-8 h-8 my-3' />
                      <h2>{selectedChat.senderId}</h2>
                    </div>
                    <div className="profile-card-toggle-content">
                      <ProfileCardToggle />
                    </div>
                  </>
                ) : (
                  <>
                    <Skeleton circle height={60} width={60} />
                    <h2><Skeleton width={120} /></h2>
                    <p><Skeleton width={80} /></p>
                  </>
                )}
              </div>

              {/* ticket history */}
              <div className="omni-ticket-history">
                <h2>Ticket History</h2>
                <div className="ticket-history-filter">
                  <input type="text" className="chat-search-input" placeholder='Search..' onChange={e => setFilteredTickets(tickets.filter(item => item._id.includes(e.target.value)))} />
                  <select name="" id="" onChange={e => setFilteredTickets(tickets.filter(item => item.ticketStatus === e.target.value))}>
                    <option value="pending">Pending</option>
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                  </select>
                </div>
                {/* ticket history list */}
                <div className="ticket-history-list">
                  {loading ? (
                    // Skeletons displayed while loading
                    [...Array(10)].map((_, index) => (
                      <li key={index}>
                        <span className='ticket-history-item'>
                          <Skeleton width={120} />
                        </span>
                        <span className='ticket-history-status'>
                          <Skeleton width={80} />
                        </span>
                      </li>
                    ))
                  ) : (
                    filteredTickets?.map((item, index) => (
                      <li key={index} onClick={() => navigate(`/tickets?ticketId=${item._id}`)} style={{cursor:'pointer'}}>
                        <span className='ticket-history-item'>Ticket ID: {item.ticketId} </span>
                        <span
                          className={
                            item.ticketStatus === 'pending'
                              ? 'ticket-history-status pending'
                              : item.ticketStatus === 'open'
                              ? 'ticket-history-status opened'
                              : item.ticketStatus === 'closed'
                              ? 'ticket-history-status closed'
                              : 'ticket-history-status'
                          }
                        >
                          {item.ticketStatus}
                        </span>
                      </li>
                    ))
                  )}
                </div>

              </div>
            </div>
        </div>
      </NewLayout>
    </>
  );
}

export default OmniChannelScreen;
