import { Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { FiCheck, FiPlusCircle } from 'react-icons/fi';
import axios from 'axios';
import { apiUrl } from '../../Variables';
import Skeleton from 'react-loading-skeleton'; // Import the Skeleton component
import 'react-loading-skeleton/dist/skeleton.css';
import toast from 'react-hot-toast';

import peopleOne from '../../images/peoples/Avatar01.png';

const CreateMemberModal = ({selectedGroup, setSelectedGroup, toggleReset, users, loading}) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([...selectedGroup.users])
  const [filteredUser, setFilteredUser] = useState()
  const [keyword, setKeyword] = useState()
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    await axios.put(`${apiUrl}/groups/${selectedGroup._id}`, {...selectedGroup, users: [...selectedUserIds]}).then(res => {
      console.log(res)
      setSelectedGroup(res.data)
      toggleReset()
      toast.success("Member added successfully!")
      setIsModalOpen(false);
    }).catch(err => {
      toast.error("Operation failed!")
      console.log(err)
    })
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSearch = (e) => {
    const value = e.target.value
    let updatedData = []

    if (value.length) {
      updatedData = users.filter(item => {
        const startsWith =
          item.first_name.toLowerCase().startsWith(value.toLowerCase()) ||
          item.last_name.toLowerCase().startsWith(value.toLowerCase())

        const includes =
          item.first_name.toLowerCase().includes(value.toLowerCase()) ||
          item.last_name.toLowerCase().includes(value.toLowerCase())

        if (startsWith) {
          return startsWith
        } else if (!startsWith && includes) {
          return includes
        } else return null
      })
    }
    setFilteredUser(updatedData)
    setKeyword(value)
  }

  return (
    <>
      <div className="add-new-group" onClick={showModal}>
        <FiPlusCircle />
        <span>Add New Member</span>
      </div>
      <Modal title="Add New Member" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="omni-ticket-history create-member-modal">
          {/* <h2>Members</h2> */}
          <div className="ticket-history-filter">
            <div className="form-group">
              <input type="text" placeholder='Search..' value={keyword} onChange={e => handleSearch(e)}/>
            </div>
          </div>
          {/* group member list */}
          <div className="recent-chat ticket-history-list group-member-wrapper modal">
            {/* Show loading skeletons while chats are loading */}
            {
              !loading ? (
                (keyword ? filteredUser : users)?.map((item, index) => (
                  <li
                    key={index}
                    className='recent-chat-item pending group-member-item'
                    onClick={() => {
                      if (selectedUserIds?.includes(item._id)) {
                        setSelectedUserIds([...selectedUserIds?.filter(id => item._id !== id)])
                      } else {
                        setSelectedUserIds([...selectedUserIds, item._id])
                      }
                    }}
                  >
                    <div className="recent-chat-item-inner">
                      <div className="recent-chat-item-content">
                        <div className="recent-chat-avatar">
                          {item ? (
                            <img src={item.profile_image} alt="people" />
                          ) : (
                            <Skeleton circle height={40} width={40} />
                          )}
                        </div>

                        {/* content */}
                        <div className="recent-chatbox-content">
                          <h2>{item ? `${item.first_name} ${item.last_name} ${item._id === userInfo._id ? "(me)" : ''}` : <Skeleton width={100} />}</h2>
                          <div className="recent-chatbox-user-chat">
                            <div className="chat-list-sub-content">
                              {/* last message */}
                              <span>
                                {item ? (
                                  item.email
                                ) : (
                                  <Skeleton width={150} />
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="channel-right">
                        {selectedUserIds?.includes(item._id) ? <FiCheck stroke="green"/> : <FiPlusCircle />}
                      </div>
                    </div>

                  </li>
                ))
              ) : (
                // Show skeletons while chats are loading
                <ul className='recent-chat'>
                  {[...Array(5)].map((_, index) => (
                    <li key={index} className='recent-chat-item pending group-member-item'>
                      <div className="recent-chat-item-inner">
                        <div className="recent-chat-item-content">
                          <div className="recent-chat-avatar">
                            <Skeleton circle height={40} width={40} />
                          </div>

                          {/* content */}
                          <div className="recent-chatbox-content">
                            <h2><Skeleton width={100} /></h2>
                            <div className="recent-chatbox-user-chat">
                              <div className="chat-list-sub-content">
                                <Skeleton width={150} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )
            }
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateMemberModal;
