import { Space, Table } from 'antd';
import React from 'react';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Biography',
    dataIndex: 'biography',
    key: 'biography',
  },
  {
    title: 'Number of Posts',
    dataIndex: 'numberOfPosts',
    key: 'numberOfPosts',
  },
  {
    title: 'Date Joined',
    dataIndex: 'dateJoined',
    key: 'dateJoined',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a>Edit</a>
        <a>Delete</a>
      </Space>
    ),
  },
];

const data = [
  {
    key: '1',
    name: 'John Brown',
    biography: 'Experienced web developer specializing in frontend technologies.',
    numberOfPosts: 12,
    dateJoined: '2022-03-15',
  },
  {
    key: '2',
    name: 'Jim Green',
    biography: 'Python enthusiast and data science expert.',
    numberOfPosts: 8,
    dateJoined: '2021-06-22',
  },
  {
    key: '3',
    name: 'Joe Black',
    biography: 'Creative web designer with a passion for user experience.',
    numberOfPosts: 10,
    dateJoined: '2020-09-12',
  },
  {
    key: '4',
    name: 'Jane Smith',
    biography: 'Digital marketing specialist focusing on SEO and content strategy.',
    numberOfPosts: 14,
    dateJoined: '2019-05-30',
  },
  {
    key: '5',
    name: 'Mike Doe',
    biography: 'Database administrator with extensive SQL knowledge.',
    numberOfPosts: 6,
    dateJoined: '2021-01-18',
  },
  {
    key: '6',
    name: 'Anna White',
    biography: 'Frontend developer with a knack for Vue.js and React.',
    numberOfPosts: 9,
    dateJoined: '2021-11-03',
  },
  {
    key: '7',
    name: 'David Black',
    biography: 'AI researcher working on machine learning algorithms.',
    numberOfPosts: 11,
    dateJoined: '2020-02-24',
  },
  {
    key: '8',
    name: 'Sarah Connor',
    biography: 'Personal development coach helping people improve their skills.',
    numberOfPosts: 7,
    dateJoined: '2019-08-19',
  },
  {
    key: '9',
    name: 'Chris Red',
    biography: 'DevOps engineer with expertise in Docker and CI/CD pipelines.',
    numberOfPosts: 13,
    dateJoined: '2018-12-10',
  },
];

function ListOfAuthorScreen() {
  return (
    <>
      <Header />
      <div className="container">
        <div className="list-of-blog-wrapper">
          <div className="table-header">
            <div className="table-title">
              <h2>Author List</h2>
            </div>
            <div className="table-right">
              <button className='default-btn'>
                <Link to="/create-author">
                  Add New Author
                </Link>
              </button>
            </div>
          </div>
          <Table columns={columns} dataSource={data} pagination={{ pageSize: 10 }} />
        </div>
      </div>
    </>
  );
}

export default ListOfAuthorScreen;
