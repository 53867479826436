export const itemsPackage = [
  {
    value: 'Freemium',
    label: 'Freemium'
  },
  {
    value: 'Growth',
    label: 'Growth'
  },
  {
    value: 'Essential',
    label: 'Essential'
  },
  {
    value: 'admin_plan',
    label: 'admin_plan'
  }
];

export const items = [
  {
    id: 1,
    name: 'John Doe',
    email: 'john.doe@example.com',
    phone: '123-456-7890',
    plan: 'Basic',
    action: 'Edit/Delete',
  },
  {
    id: 2,
    name: 'Jane Smith',
    email: 'jane.smith@example.com',
    phone: '987-654-3210',
    plan: 'Premium',
    action: 'Edit/Delete',
  },
  {
    id: 3,
    name: 'Alice Johnson',
    email: 'alice.johnson@example.com',
    phone: '555-123-4567',
    plan: 'Standard',
    action: 'Edit/Delete',
  },
  {
    id: 4,
    name: 'Bob Brown',
    email: 'bob.brown@example.com',
    phone: '444-789-0123',
    plan: 'Freemium',
    action: 'Edit/Delete',
  },
  {
    id: 5,
    name: 'Charlie Davis',
    email: 'charlie.davis@example.com',
    phone: '333-567-8901',
    plan: 'Basic',
    action: 'Edit/Delete',
  },
  {
    id: 6,
    name: 'David Smith',
    email: 'david.smith@example.com',
    phone: '222-345-6789',
    plan: 'Premium',
    action: 'Edit/Delete',
  },
  {
    id: 7,
    name: 'Emma Wilson',
    email: 'emma.wilson@example.com',
    phone: '666-987-6543',
    plan: 'Freemium',
    action: 'Edit/Delete',
  },
  {
    id: 8,
    name: 'Frank Johnson',
    email: 'frank.johnson@example.com',
    phone: '789-456-1230',
    plan: 'Standard',
    action: 'Edit/Delete',
  },
  {
    id: 9,
    name: 'Grace Miller',
    email: 'grace.miller@example.com',
    phone: '345-678-9012',
    plan: 'Basic',
    action: 'Edit/Delete',
  },
  {
    id: 10,
    name: 'Henry Brown',
    email: 'henry.brown@example.com',
    phone: '567-890-1234',
    plan: 'Premium',
    action: 'Edit/Delete',
  },
  {
    id: 11,
    name: 'Isabel Davis',
    email: 'isabel.davis@example.com',
    phone: '678-901-2345',
    plan: 'Freemium',
    action: 'Edit/Delete',
  },
  {
    id: 12,
    name: 'Jack Wilson',
    email: 'jack.wilson@example.com',
    phone: '789-012-3456',
    plan: 'Standard',
    action: 'Edit/Delete',
  },
  {
    id: 13,
    name: 'Katherine Smith',
    email: 'katherine.smith@example.com',
    phone: '890-123-4567',
    plan: 'Basic',
    action: 'Edit/Delete',
  },
  {
    id: 14,
    name: 'Leo Johnson',
    email: 'leo.johnson@example.com',
    phone: '123-234-5678',
    plan: 'Premium',
    action: 'Edit/Delete',
  },
  {
    id: 15,
    name: 'Mia Brown',
    email: 'mia.brown@example.com',
    phone: '234-345-6789',
    plan: 'Freemium',
    action: 'Edit/Delete',
  },
  {
    id: 16,
    name: 'Noah Davis',
    email: 'noah.davis@example.com',
    phone: '345-456-7890',
    plan: 'Standard',
    action: 'Edit/Delete',
  },
  {
    id: 17,
    name: 'Olivia Smith',
    email: 'olivia.smith@example.com',
    phone: '456-567-8901',
    plan: 'Basic',
    action: 'Edit/Delete',
  },
  {
    id: 18,
    name: 'Pablo Wilson',
    email: 'pablo.wilson@example.com',
    phone: '567-678-9012',
    plan: 'Premium',
    action: 'Edit/Delete',
  },
  {
    id: 19,
    name: 'Quinn Johnson',
    email: 'quinn.johnson@example.com',
    phone: '678-789-0123',
    plan: 'Freemium',
    action: 'Edit/Delete',
  },
  {
    id: 20,
    name: 'Rachel Brown',
    email: 'rachel.brown@example.com',
    phone: '789-890-1234',
    plan: 'Standard',
    action: 'Edit/Delete',
  },
  {
    id: 21,
    name: 'Samuel Davis',
    email: 'samuel.davis@example.com',
    phone: '890-901-2345',
    plan: 'Basic',
    action: 'Edit/Delete',
  },
  {
    id: 22,
    name: 'Taylor Smith',
    email: 'taylor.smith@example.com',
    phone: '901-012-3456',
    plan: 'Premium',
    action: 'Edit/Delete',
  },
  {
    id: 23,
    name: 'Ursula Wilson',
    email: 'ursula.wilson@example.com',
    phone: '012-123-4567',
    plan: 'Freemium',
    action: 'Edit/Delete',
  },
  {
    id: 24,
    name: 'Victor Johnson',
    email: 'victor.johnson@example.com',
    phone: '123-234-5678',
    plan: 'Standard',
    action: 'Edit/Delete',
  },
  {
    id: 25,
    name: 'Wendy Brown',
    email: 'wendy.brown@example.com',
    phone: '234-345-6789',
    plan: 'Basic',
    action: 'Edit/Delete',
  },
  {
    id: 26,
    name: 'Xavier Davis',
    email: 'xavier.davis@example.com',
    phone: '345-456-7890',
    plan: 'Premium',
    action: 'Edit/Delete',
  },
  {
    id: 27,
    name: 'Yasmine Smith',
    email: 'yasmine.smith@example.com',
    phone: '456-567-8901',
    plan: 'Freemium',
    action: 'Edit/Delete',
  },
  {
    id: 28,
    name: 'Zachary Wilson',
    email: 'zachary.wilson@example.com',
    phone: '567-678-9012',
    plan: 'Standard',
    action: 'Edit/Delete',
  },
  {
    id: 29,
    name: 'Audrey Johnson',
    email: 'audrey.johnson@example.com',
    phone: '678-789-0123',
    plan: 'Basic',
    action: 'Edit/Delete',
  },
  {
    id: 30,
    name: 'Benjamin Brown',
    email: 'benjamin.brown@example.com',
    phone: '789-890-1234',
    plan: 'Premium',
    action: 'Edit/Delete',
  },
  {
    id: 31,
    name: 'Xander Davis',
    email: 'xander.davis@example.com',
    phone: '890-901-2345',
    plan: 'Freemium',
    action: 'Edit/Delete',
  },
  {
    id: 32,
    name: 'Yara Smith',
    email: 'yara.smith@example.com',
    phone: '901-012-3456',
    plan: 'Standard',
    action: 'Edit/Delete',
  },
  {
    id: 33,
    name: 'Zane Wilson',
    email: 'zane.wilson@example.com',
    phone: '012-123-4567',
    plan: 'Basic',
    action: 'Edit/Delete',
  },
  {
    id: 34,
    name: 'Alexis Johnson',
    email: 'alexis.johnson@example.com',
    phone: '123-234-5678',
    plan: 'Premium',
    action: 'Edit/Delete',
  },
  {
    id: 35,
    name: 'Bryce Brown',
    email: 'bryce.brown@example.com',
    phone: '234-345-6789',
    plan: 'Freemium',
    action: 'Edit/Delete',
  },
  {
    id: 36,
    name: 'Carla Davis',
    email: 'carla.davis@example.com',
    phone: '345-456-7890',
    plan: 'Standard',
    action: 'Edit/Delete',
  },
  {
    id: 37,
    name: 'Dylan Smith',
    email: 'dylan.smith@example.com',
    phone: '456-567-8901',
    plan: 'Basic',
    action: 'Edit/Delete',
  },
  {
    id: 38,
    name: 'Eva Wilson',
    email: 'eva.wilson@example.com',
    phone: '567-678-9012',
    plan: 'Premium',
    action: 'Edit/Delete',
  },
  {
    id: 39,
    name: 'Finn Johnson',
    email: 'finn.johnson@example.com',
    phone: '678-789-0123',
    plan: 'Freemium',
    action: 'Edit/Delete',
  },
  {
    id: 40,
    name: 'Giselle Brown',
    email: 'giselle.brown@example.com',
    phone: '789-890-1234',
    plan: 'Standard',
    action: 'Edit/Delete',
  },
  {
    id: 41,
    name: 'Hugo Davis',
    email: 'hugo.davis@example.com',
    phone: '890-901-2345',
    plan: 'Basic',
    action: 'Edit/Delete',
  },
  {
    id: 42,
    name: 'Ivy Smith',
    email: 'ivy.smith@example.com',
    phone: '901-012-3456',
    plan: 'Premium',
    action: 'Edit/Delete',
  },
  {
    id: 43,
    name: 'Jason Wilson',
    email: 'jason.wilson@example.com',
    phone: '012-123-4567',
    plan: 'Freemium',
    action: 'Edit/Delete',
  },
  {
    id: 44,
    name: 'Kylie Johnson',
    email: 'kylie.johnson@example.com',
    phone: '123-234-5678',
    plan: 'Standard',
    action: 'Edit/Delete',
  },
  {
    id: 45,
    name: 'Liam Brown',
    email: 'liam.brown@example.com',
    phone: '234-345-6789',
    plan: 'Basic',
    action: 'Edit/Delete',
  },
  {
    id: 46,
    name: 'Megan Davis',
    email: 'megan.davis@example.com',
    phone: '345-456-7890',
    plan: 'Premium',
    action: 'Edit/Delete',
  },
  {
    id: 47,
    name: 'Nathan Smith',
    email: 'nathan.smith@example.com',
    phone: '456-567-8901',
    plan: 'Freemium',
    action: 'Edit/Delete',
  },
  {
    id: 48,
    name: 'Olivia Wilson',
    email: 'olivia.wilson@example.com',
    phone: '567-678-9012',
    plan: 'Standard',
    action: 'Edit/Delete',
  },
  {
    id: 49,
    name: 'Preston Johnson',
    email: 'preston.johnson@example.com',
    phone: '678-789-0123',
    plan: 'Basic',
    action: 'Edit/Delete',
  },
  {
    id: 50,
    name: 'Quincy Brown',
    email: 'quincy.brown@example.com',
    phone: '789-890-1234',
    plan: 'Premium',
    action: 'Edit/Delete',
  },
];

// export default items;
