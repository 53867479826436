import { Space, Table, Tag } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Author',
    dataIndex: 'author',
    key: 'author',
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'loser') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a>Edit</a>
        <a>Delete</a>
      </Space>
    ),
  },
];

const data = [
  {
    key: '1',
    title: 'React Introduction',
    category: 'Web Development',
    author: 'John Brown',
    tags: ['react', 'javascript'],
  },
  {
    key: '2',
    title: 'Learning Python',
    category: 'Programming',
    author: 'Jim Green',
    tags: ['python'],
  },
  {
    key: '3',
    title: 'CSS Tips and Tricks',
    category: 'Web Design',
    author: 'Joe Black',
    tags: ['css', 'design'],
  },
  {
    key: '4',
    title: 'Advanced Node.js',
    category: 'Backend Development',
    author: 'John Brown',
    tags: ['nodejs', 'backend'],
  },
  {
    key: '5',
    title: 'SEO Best Practices',
    category: 'Digital Marketing',
    author: 'Jane Smith',
    tags: ['seo', 'marketing'],
  },
  {
    key: '6',
    title: 'Understanding Databases',
    category: 'Database',
    author: 'Mike Doe',
    tags: ['database', 'sql'],
  },
  {
    key: '7',
    title: 'Vue.js for Beginners',
    category: 'Web Development',
    author: 'Anna White',
    tags: ['vuejs', 'frontend'],
  },
  {
    key: '8',
    title: 'Machine Learning Basics',
    category: 'Artificial Intelligence',
    author: 'David Black',
    tags: ['machine learning', 'ai'],
  },
  {
    key: '9',
    title: 'Effective Communication',
    category: 'Personal Development',
    author: 'Sarah Connor',
    tags: ['communication', 'skills'],
  },
  {
    key: '10',
    title: 'Introduction to Docker',
    category: 'DevOps',
    author: 'Chris Red',
    tags: ['docker', 'devops'],
  },
];

function ListOfBlogs() {
  return (
    <>
      <Header />
      <div className="container">
        <div className="list-of-blog-wrapper">
          <div className="table-header">
            <div className="table-title">
              <h2>Article List</h2>
            </div>
            <div className="table-right">
              <button className='default-btn'>
                <Link to="/create-blog">
                  Add New Post
                </Link>
              </button>
            </div>
          </div>
          <Table columns={columns} dataSource={data} pagination={{ pageSize: 10 }} />
        </div>
      </div>
    </>
  );
}

export default ListOfBlogs;
