import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import axios from 'axios';
import { ChevronLeft, ChevronRight } from 'lucide-react'; // Import the ChevronLeft and ChevronRight icons
import React, { useEffect, useState } from 'react';
import { GrPowerReset } from 'react-icons/gr'; // Import the GrPowerReset icon
import { useSearchParams } from 'react-router-dom';
import NewLayout from '../../components/NewLayout';
import { formatDateToHumanReadableWithTime } from '../../utils/helper';
import { apiUrl } from '../../Variables';
import AppointmentDrawer from './AppointmentDrawer';

const AllAppointmentLogScreen = ({ toggleLoggedInState }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const icnumber = searchParams?.get("icnumber")
  const [currentPage, setCurrentPage] = useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedAppnt, setSelectedAppnt] = useState(null);
  const [appnt, setAppnt] = useState(null);
  const [filteredAppnt, setFilteredAppnt] = useState(null); 
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [resetData, setResetData] = useState(false);
  const toggleReset = () => {
    setResetData(!resetData);
  };

  const handleSearch = (value) => {
    setSearchValue(value)
    const filtered = appnt.filter(
      item =>
        item?.apptId?.includes(value) ||
        item?.fullName?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item?.contact?.includes(value) ||
        item?.idPassport?.includes(value)
    );
    setFilteredAppnt(filtered);
  };

  const handleRowClick = (record) => {
    console.log(record)
    setSelectedAppnt(record);
    setDrawerVisible(true);
    setSearchParams({})
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
    setSelectedAppnt(null);
  };

  const handleReset = () => {
    setFilteredAppnt(appnt);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (icnumber) {
      setSelectedAppnt(appnt?.find(item => item.idPassport === icnumber));
      setDrawerVisible(true);
    }
  }, [icnumber, appnt])

  // fetch group data []
  useEffect(() => {
    const fetchGroup = async () => {
      try {
        // Retrieve userInfo from localStorage
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (!userInfo || !userInfo.token) {
          console.error('User token not found.');
          return;
        }

        // Add headers to include JWT token
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        };

        setLoading(true);

        const res = await axios.get(`${apiUrl}/appointments/uniqueIC`, { headers });
        setAppnt(res.data.map(item => { return {...item, createdAt: formatDateToHumanReadableWithTime(item.createdAt)} }));
        setFilteredAppnt(res.data.map(item => { return {...item, createdAt: formatDateToHumanReadableWithTime(item.createdAt)} }));
        console.log(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Chats fetch error:', error);
      }
    };
    fetchGroup();
  }, [resetData]);

  const columns = [
    {
      title: 'Patient Name',
      dataIndex: 'fullName',
      key: 'fullName',
      // render: (text) => <b>{text}</b>,
    },
    {
      title: 'IC/Passport',
      dataIndex: 'idPassport',
      key: 'idPassport',
    },
    {
      title: 'Phone',
      dataIndex: 'contact',
      key: 'contact',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
    }
  ];

  return (
    <>
      {/* <Header toggleLoggedInState={toggleLoggedInState} /> */}
      <NewLayout>
      <div className="all-ticket-list-screen">
          <div className="ticket-title">
            <h3>Appointment Logs</h3>
          </div>
        <div className="">
        <div className="filter-bar flex justify-start items-center gap-1">
          <Input
            placeholder="Search by name or phone"
            value={searchValue}
            onChange={e => handleSearch(e.target.value)}
            prefix={<SearchOutlined />}
            style={{ width: 300, marginRight: 10, marginBottom: 10 }}
          />
          <Button onClick={handleReset} icon={<GrPowerReset />} style={{ marginRight: 10, marginBottom: 10 }}>
            Reset
          </Button>
        </div>
          <Table 
            dataSource={filteredAppnt} 
            columns={columns} 
            pagination={{
              current: currentPage,
              pageSize: 9,
              total: filteredAppnt?.length,
              onChange: (page) => setCurrentPage(page),
              showSizeChanger: false,
              itemRender: (current, type, element) => {
                if (type === "prev") {
                  return <ChevronLeft />;
                }
                if (type === "next") {
                  return <ChevronRight />;
                }
                return element;
              },
            }}
            // rowSelection={{ type: 'checkbox' }}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            rowClassName={(record) => record.status === 'Open' ? 'open-tickets' : ''}
          />
        </div>
      </div>
      <AppointmentDrawer
        visible={drawerVisible}
        onClose={handleCloseDrawer}
        appointments={selectedAppnt}
      />
      </NewLayout>
    </>
  );
};

export default AllAppointmentLogScreen;
