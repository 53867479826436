import { CloseOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Drawer, Input, List, Select, Spin, Tag } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { apiUrl } from '../../Variables';
import avatar from '../../images/icons/Avatar.png';
import { useNavigate } from 'react-router-dom';
import { Search } from 'lucide-react';

const { Option } = Select;

const AppointmentDrawer = ({ visible, onClose, appointments }) => {
  const navigate = useNavigate()
  const [apptList, setApptList] = useState([]);
  const [filteredAppt, setFilteredAppt] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (!userInfo || !userInfo.token) {
          console.error('User token not found.');
          return;
        }

        setLoading(true);
        const headers = { Authorization: `Bearer ${userInfo.token}` };
        const res = await axios.post(`${apiUrl}/appointments/allApptByUniqeIC`, { idPassport: appointments.idPassport }, { headers });
        setApptList(res.data);
      } catch (error) {
        console.error('Appointments fetch error:', error);
      } finally {
        setLoading(false);
      }
    };

    if (visible) {
      fetchAppointments();
    }
  }, [visible, appointments]);

  const handleSearch = (value) => {
    setSearchValue(value)
    setFilteredAppt(apptList?.filter(item => item.apptId.includes(value)));
  };

  return (
    <Drawer
      title={<span className="font-semibold text-lg">Appointments</span>}
      placement="right"
      onClose={onClose}
      open={visible}
      width={400}
      closeIcon={<CloseOutlined className="text-gray-600 hover:text-primary transition-colors" />}
    >
      <Spin spinning={loading}>
        <div className="flex flex-col h-full">
          <div className="bg-gradient-to-r from-stone-50 to-stone-100 p-2 py-3 text-center rounded-lg mb-6 bg-green-50">
            <Avatar 
              size={80} 
              icon={<UserOutlined />} 
              src={avatar}
              className="mb-2 border-4 border-white shadow-lg"
            />
            <h2 className="text-xl font-bold !text-primary">{appointments?.fullName}</h2>
          </div>

          <div className="flex-grow overflow-y-auto">
            <h3 className="text-base font-semibold text-gray-800 mb-3 p-0 text-left">Appointment History</h3>
            <div className="space-y-4 mb-6">
              <Input
                placeholder="Search appointments"
                prefix={<Search />}
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
                className="w-full rounded-lg border-gray-300 focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
              />
            </div>

            <List
              itemLayout="horizontal"
              dataSource={searchValue ? filteredAppt : apptList}
              renderItem={(item) => (
                <List.Item className="py-1 hover:bg-gray-50 transition-colors">
                  <List.Item.Meta
                    className="flex justify-between items-center"
                    title={<span className="text-gray-800 font-semibold">Appt ID: {item.apptId}</span>}
                    description={
                      item.appointmentStatus === 'cancelled' ? 'Cancelled' : <button className='!bg-primary text-white px-2 py-1 rounded-md !hover:bg-primary-bold !border-none' onClick={() => navigate(`/calendar?apptId=${item._id}`)}>Calendar</button>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </Spin>
    </Drawer>
  );
};

export default AppointmentDrawer;
