import { Popover } from 'antd';
import React from 'react';
import { BiSupport } from "react-icons/bi";
import { FaRegMessage } from "react-icons/fa6";
import { GoPeople } from "react-icons/go";
import { IoAnalytics, IoCalendarClearOutline } from "react-icons/io5";
import { LuCalendarClock } from "react-icons/lu";
import 'react-loading-skeleton/dist/skeleton.css';
import { NavLink } from 'react-router-dom';
import Header from './Header';



function NewLayout({children}) {

  return (
    <>
      <main>
        <section className="layout-section">
          <div className="layout-container">
            <Header />
            <div className="layout-wrapper">
              {/* menus */}
              <div className="omni-sidebar-menu-wrapper">
                {/* <ul>
                  <li>
                      <Popover placement="right" title="Home">
                        <NavLink to="/" className={({ isActive }) => (isActive ? 'active-menu' : '')}>
                          <RiHome2Line />
                        </NavLink>
                      </Popover>
                  </li> 
                </ul> */}
                <ul>
                  <li>
                    <Popover placement="right" title="Tickets">
                      <NavLink to="/" className={({ isActive }) => (isActive ? 'active-menu' : '')}>
                      <BiSupport />
                      </NavLink>
                    </Popover>
                  </li>
                  <li>
                    <Popover placement="right" title="Appointments">
                      <NavLink to="/appointments" className={({ isActive }) => (isActive ? 'active-menu' : '')}>
                      <LuCalendarClock />
                      </NavLink>
                    </Popover>
                  </li>
                  <li>
                    <Popover placement="right" title="Omnichannels">
                      <NavLink to="/omnichannels" className={({ isActive }) => (isActive ? 'active-menu' : '')}>
                        <FaRegMessage />
                      </NavLink>
                    </Popover>
                  </li> 
                  <li>
                    <Popover placement="right" title="Groups">
                      <NavLink to="/groups" className={({ isActive }) => (isActive ? 'active-menu' : '')}>
                        <GoPeople />
                      </NavLink>
                    </Popover>
                  </li>
                  <li>
                    <Popover placement="right" title="Calendar">
                      <NavLink to="/calendar" className={({ isActive }) => (isActive ? 'active-menu' : '')}>
                        <IoCalendarClearOutline />
                      </NavLink>
                    </Popover>
                  </li>
                  <li>
                    <Popover placement="right" title="Data Analytics">
                      <NavLink to="/mlDA" className={({ isActive }) => (isActive ? 'active-menu' : '')}>
                        <IoAnalytics />
                      </NavLink>
                    </Popover>
                  </li>
                </ul>
              </div>
              {/* layout body */}
              <div className="layout-body">
                {children}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default NewLayout