import React from 'react';
import { NumberOutlined, CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Spinner } from 'react-bootstrap';

const Kpi = ({ totalTickets, openTickets, closeTickets, pendingTickets, loading }) => {
  return (
    <div className="dashboard-kpi-wrapper ticket-kpi-wrapper">
      {/* Total Tickets */}
      <div className="dashboard-kpi-item">
        <div className="dashboard-kpi-header">
          <div className="dashboard-kpi-title">
            <p>Total Tickets</p>
            <p className='dashboard-kpi-count'>{loading ? <Spinner animation="border" role="status" size='sm'/> : totalTickets}</p>
          </div>
        </div>
      </div>

      {/* Open Tickets */}
      <div className="dashboard-kpi-item">
        <div className="dashboard-kpi-header">
          <div className="dashboard-kpi-title">
            <p>Open Tickets</p>
            <p className='dashboard-kpi-count'>{loading ? <Spinner animation="border" role="status" size='sm'/> : openTickets}</p>
          </div>
        </div>
      </div>

      {/* Close Tickets */}
      <div className="dashboard-kpi-item">
        <div className="dashboard-kpi-header">
          <div className="dashboard-kpi-title">
            <p>Close Tickets</p>
            <p className='dashboard-kpi-count'>{loading ? <Spinner animation="border" role="status" size='sm'/> : closeTickets}</p>
          </div>
        </div>
      </div>

      {/* Pending Tickets */}
      <div className="dashboard-kpi-item">
        <div className="dashboard-kpi-header">
          <div className="dashboard-kpi-title">
            <p>Pending Tickets</p>
            <p className='dashboard-kpi-count'>{loading ? <Spinner animation="border" role="status" size='sm'/> : pendingTickets}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kpi;
