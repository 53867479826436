import { Button, Form, Input, Select } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';

const { Option } = Select;

const categories = ['Web Development', 'Programming', 'Web Design', 'Backend Development', 'Digital Marketing', 'Database', 'Artificial Intelligence', 'Personal Development', 'DevOps'];

function CreateBlogScreen() {
  const history = useNavigate();

  const onFinish = (values) => {
    console.log('Success:', values);
    // Here you can handle the form submission, such as sending data to your server

    // Navigate back to the blog list after submission
    // history.push('/blog-list');
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
    <Header />
      <div className="list-of-blog-wrapper">
      <div className="container">
        <div className="table-header">
            <div className="table-title">
              <h2>Create New Blog Post</h2>
            </div>
            <div className="table-right">
              <button className='default-btn'>
                <Link to="/create-blog">
                  Save
                </Link>
              </button>
            </div>
          </div>
          <Form
            name="create-blog"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: 'Please input the title!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Category"
              name="category"
              rules={[{ required: true, message: 'Please select a category!' }]}
            >
              <Select placeholder="Select a category">
                {categories.map((category) => (
                  <Option key={category} value={category}>
                    {category}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Author"
              name="author"
              rules={[{ required: true, message: 'Please input the author!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Tags"
              name="tags"
              rules={[{ required: true, message: 'Please input tags!' }]}
            >
              <Input placeholder="Enter tags separated by commas" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}

export default CreateBlogScreen;
