import { CalendarOutlined, ClockCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import moment from 'moment';
import React from 'react';
import { FaUserDoctor } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';

const CalendarDetailSidebar = ({ event, onClose }) => {
  const navigate = useNavigate()
  if (!event) return null;
  const formatDate = (date, format) => {
    return moment(date).format(format);
  };

  return (
    <Drawer
      title="Event Details"
      placement="right"
      onClose={onClose}
      visible={!!event}
      width={400}
      bodyStyle={{ padding: 0 }}
    >
      <div className="flex flex-col h-full bg-gradient-to-r from-stone-50 to-stone-100">
        <div className="px-6 py-8 space-y-6">
          <div className="flex items-center space-x-4">
            {/* <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center border-4 border-white drop-shadow-xl">
              <UserOutlined style={{ fontSize: '24px', color: '#3B82F6' }} />
            </div> */}
            <div>
              <h3 className="text-2xl font-bold text-gray-900 p-[0px]">{event.title}</h3>
            </div>
          </div>
          <div className="border-t border-gray-200 pt-6">
            <div className="space-y-4">
              <div className="flex items-center space-x-3 text-gray-700">
                <FaUserDoctor />
                <span className='!font-semibold'>{event.doctor}</span>
              </div>
              <div className="flex items-center space-x-3 text-gray-700">
                <CalendarOutlined style={{ color: '#3B82F6' }} />
                <span>{formatDate(event.start, 'MMMM D, YYYY')}</span>
              </div>
              <div className="flex items-center space-x-3 text-gray-700">
                <ClockCircleOutlined style={{ color: '#3B82F6' }} />
                <span>{formatDate(event.start, 'h:mm A')}</span>
              </div>
              <div className="flex items-center space-x-3 text-gray-700">
                <EnvironmentOutlined style={{ color: '#3B82F6' }} />
                <span>Virtual Appointment</span>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 pt-6">
            <h4 className="mb-2 text-lg font-semibold text-gray-800">Description</h4>
            <p className="text-gray-600" dangerouslySetInnerHTML={{ __html: event.description }} />
          </div>
        </div>
        <div className="mt-auto px-6 py-4 bg-white border-t">
        <Link to={`/appointments?icnumber=${event.idPassport}`}>
          <button 
            className="w-full px-4 py-2 text-white !bg-primary rounded-md !hover:bg-primary-bold focus:outline-none focus:ring-2 focus:bg-primary-bold focus:ring-offset-2 font-poppins"
            // onClick={() => navigate(`/appointments?icnumber=${event.idPassport}`)}
          >
              Appointment Logs
          </button>
          </Link>
        </div>
      </div>
    </Drawer>
  );
};

export default CalendarDetailSidebar;