import { Button, Select, Checkbox, message } from 'antd';
import React, { useState } from 'react';
import './TicketDrawer.css';
import {formatDateToHumanReadable, formatDateToHumanReadableWithTime} from '../../utils/helper'
import { apiUrl } from '../../Variables';
import axios from 'axios';
import DateComponent from './DateComponent';
import toast from 'react-hot-toast';

const { Option } = Select;

const AllTabs = ({ ticket, toggleRefresh, onClose, setSelectedTicket }) => {
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [isReschedule, setIsReschedule] = useState(false);
  const [firstChoiceDate, setFirstChoiceDate] = useState(null);
  const [secondChoiceDate, setSecondChoiceDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDoctorChange = (value) => {
    setSelectedDoctor(value);
  };

  const handleFirstChoiceDateChange = (value, dateString) => {
    setFirstChoiceDate(dateString);
  };

  const handleSecondChoiceDateChange = (value, dateString) => {
    setSecondChoiceDate(dateString);
  };

  const handleConfirm = async () => {
    // if (selectedDoctor && firstChoiceDate && secondChoiceDate) {
    //   console.log('Doctor:', selectedDoctor);
    //   console.log('1st Choice Date:', firstChoiceDate);
    //   console.log('2nd Choice Date:', secondChoiceDate);
    //   onClose();
    // } else {
    //   message.error('Please select a doctor and both appointment dates.');
    // }
    const userConfirmed = window.confirm('Are you sure you want to proceed?');

    if (userConfirmed) {

      if (ticket.type === 'cancel_appointment' ) {
        await axios.get(`${apiUrl}/appointments/${ticket?.appointmentIdRef}`).then(async res => {
          console.log(res)
          await axios.put(`${apiUrl}/appointments/${res.data._id}`, {...res.data, appointmentStatus: 'cancelled'}).then(async res => {
            console.log(res)
            toast.success('Cancelled Successfully!')
            await axios.put(`${apiUrl}/tickets/${ticket._id}`, {...ticket, ticketStatus: 'closed', appointmentIdRef: res.data.apptId }).then(res => {
              console.log(res)
              toggleRefresh()
              setLoading(false)
              onClose()
            }).catch(err => {
              console.log(err)
            })
          }).catch(err => {
            console.log(err)
            setLoading(false)
            toast.error('Update failed!')
          })
        }).catch(err => {
          setLoading(false)
          toast.error('Invalid Appointment ID!')
          console.log(err)
        })
        return
      }

      await axios.put(`${apiUrl}/tickets/${ticket._id}`, {...ticket, ticketStatus: 'open'}).then(res => {
        console.log(res)
        setSelectedTicket({...ticket, ticketStatus: 'open'})
        toggleRefresh()
        // onClose()
      }).catch(err => {
        console.log(err)
      })
    } else {
      // User clicked "Cancel"
      console.log('User canceled the action');
    }
  };

  const handleDone = () => {
    axios.put(`${apiUrl}/tickets/${ticket._id}`, {...ticket, ticketStatus: 'closed'}).then(res => {
      console.log(res)
      toggleRefresh()
      onClose()
    }).catch(err => {
      console.log(err)
    })
  };

  if (!ticket) return null;

  return (
    <div className="ticket-details">
       <div className="ticket-details-group">
          <h2>Patient’s Information</h2>
          <p><strong>Patient's Full Name:</strong> {ticket.fullName}</p>
          <p><strong>Date of Birth:</strong> {formatDateToHumanReadable(ticket.dateOfBirth)}</p>
          <p><strong>IC Number (Malaysian):</strong> {ticket.idPassport}</p>
          <p><strong>Gender:</strong> {ticket.gender}</p>
          <p><strong>Contact Number:</strong> {ticket.contact}</p>
          <p><strong>Email Address:</strong> {ticket.email}</p>
          <p><strong>Medical condition:</strong> {ticket.symptoms}</p>
        </div>
      <div className="ticket-details-group">
        <h2>Ticket Information</h2>
        <p><strong>Ticket Id:</strong> {ticket?.ticketId}</p>
        {ticket?.appointmentIdRef && <p><strong>Appointment Id:</strong> {ticket?.appointmentIdRef}</p>}
        <p><strong>Ticket Type:</strong> {ticket.type}</p>
        {ticket.type !== 'cancel_appointment' && <p><strong>Appointment date:</strong> {formatDateToHumanReadableWithTime(ticket.appointmentDate)}</p>}
        {ticket.type !== 'cancel_appointment' && <p><strong>Appointment date two:</strong> {formatDateToHumanReadableWithTime(ticket.appointmentDateTwo)}</p>}
        <p><strong>Preferred Doctor (if any):</strong> {ticket.doctor}</p>
      </div>

      {
        ticket?.ticketStatus === 'pending' && <div className="ticket-details">
        <div className="drawer-buttons" style={{ marginTop: '20px' }}>
          <Button type="primary" style={{ marginRight: 10 }} onClick={handleConfirm}>Accept Ticket</Button>
        </div>
      </div>
      }
      {/* {
        ticket?.ticketStatus === 'open' && <div className="ticket-details">
        <div className="drawer-buttons" style={{ marginTop: '20px' }}>
          <Button type="primary" style={{ marginRight: 10 }} onClick={handleDone}>Done</Button>
        </div>
      </div>
      } */}
          
    </div>
  );
};

export default AllTabs;
