import { Select } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { LuSearch } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { itemsPackage } from '../../utils/data';
import { apiUrl } from '../../Variables';
import './listOfUsers.css';
import PaginatedItems from './pagination';

const ListOfUsers = ({toggleLoggedInState}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [filteredUser, setFilteredUser] = useState([]);

  useEffect(() => {
    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (!userInfo || !userInfo.token) {
      console.error('User token not found.');
      return;
    }

    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    const featchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${apiUrl}/user/user_report`, { headers });
        console.log(res)
        setUsers(res.data);
        setFilteredUser(res.data);
        setLoading(false)
      } catch (error) {
        setLoading(false);
        console.log('Kpis fetching fail:', error);
        toast.error(`Error: ${error?.message || error?.response?.data}`);
      }
    };
    featchData();
  }, []);

  const handleSearch = (searchValue) => {
    const searchResults = users.filter(user =>
      searchValue.toLowerCase().includes(user.first_name?.toLowerCase()) ||
      searchValue.toLowerCase().includes(user.last_name?.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
      user.phone?.includes(searchValue)
    );
    setFilteredUser(searchResults)
  }

  return (
    <>
      <Header toggleLoggedInState={toggleLoggedInState}/>
      <div className="user-section common-margin">
        <div className="container">
          <div className="user-container">
            <h3>Tickets</h3>
            <div className="search-user">
              <div className="search-user-box">
                <LuSearch />
                <input placeholder="Search for users..." onChange={e => {
                  handleSearch(e.target.value)
                  }}/>
              </div>
              <div className="plan-package">
                <div>
                  <p style={{ color: 'black' }}>Plan :</p>
                </div>
                <div className="plan-package-box">
                  <Select
                    defaultValue={{value:'', label: 'Select...'}}
                    variant="borderless"
                    style={{
                      width: 120,
                    }}
                    onChange={(selected) => {
                      setFilteredUser([...users.filter(item => item.plan.name === selected)])
                    }}
                    options={itemsPackage}
                  />
                </div>
              </div>
              <div>
                <button className='form-button'>
                  <span>Create User</span>
                </button>
              </div>
            </div>
            <div>
              <PaginatedItems itemsPerPage={10} users={filteredUser} loading={loading}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListOfUsers;
