import React from 'react';
import { Form, Input, Button, DatePicker } from 'antd';
import { Link, useHistory, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';

const CreateAuthorScreen = () => {
  const [form] = Form.useForm();
  const history = useNavigate();

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    // Simulate an API call
    setTimeout(() => {
      alert('Author created successfully!');
      history.push('/author-list');
    }, 1000);
  };

  return (
    <>
    <Header />
      <div className="list-of-blog-wrapper">
      <div className="container">
        <div className="table-header">
            <div className="table-title">
              <h2>Create New Author</h2>
            </div>
            <div className="table-right">
              <button className='default-btn'>
                <Link to="/create-blog">
                  Save
                </Link>
              </button>
            </div>
          </div>
          <Form
            form={form}
            name="create_author"
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please input the author name!' }]}
            >
              <Input placeholder="Enter author name" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: 'Please input the author email!' }, { type: 'email', message: 'The input is not valid E-mail!' }]}
            >
              <Input placeholder="Enter author email" />
            </Form.Item>

            <Form.Item
              name="joinDate"
              label="Join Date"
              rules={[{ required: true, message: 'Please select the join date!' }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create Author
              </Button>
            </Form.Item>
          </Form>
          </div>
      </div>
    </>
  );
};

export default CreateAuthorScreen;
