import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ path, element, isAuthenticated }) => {
  return isAuthenticated ? element : <Navigate to="/login" />;
};

const PublicRoute = ({ path, element, isAuthenticated, userInfo }) => {
  // return isAuthenticated ? <Navigate to="/" /> : element;
  if (isAuthenticated) {
    return <Navigate to="/" />;
  } else {
    return element;
  }
};
const checkIsAuthenticate = (userInfo) => {
  if (userInfo?.token) {
    return true;
  } else {
    return false;
  }
};
export { ProtectedRoute, PublicRoute, checkIsAuthenticate };
